import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, useMediaQuery, useTheme } from '@mui/material';
import { testTypes } from '../../config/TestTypes';

const TestTypeFilters = ({ selected, onChange }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <FormControl fullWidth style={{ minWidth: 120 }}>
            <InputLabel id="test-type-select-label">Test Type</InputLabel>
            <Select
                labelId="test-type-select-label"
                id="test-type-select"
                value={selected}
                label="Test Type"
                onChange={onChange}
                size={isMobile ? 'small' : 'medium'}
            >   
                <MenuItem value="all">All Test Types</MenuItem>
                {testTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                        {type.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TestTypeFilters;
