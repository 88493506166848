import React from 'react';
import { Typography, Grid, Card } from '@mui/material';
import ResultIndicator from '../../Results/ResultIndicator';
import './ProjectCard.css';
import Skeleton from '@mui/material/Skeleton';

const ProjectCard = ({ _id, name, onCardClick, results }) => {
    const totalPassed = results.filter(result => result.pass === 1).length;
    const totalFailed = results.filter(result => result.pass === 0).length;
    const totalScheduled = results.filter(result => result.pass !== 1 && result.pass !== 0).length;
    const allPassed = totalFailed === 0 && totalScheduled === 0 && results.length > 0;

    return (
        <>
            {!results ? (
                // Render Skeletons when content is loading
                <>
                    <Skeleton className="project-card" variant="rectangular" animation="wave" />
                </>
            ) : (
                <Card className="project-card" onClick={() => onCardClick(_id)} sx={{ position: 'relative', borderRadius: '10px' }}>
                    <div className="project-summary">
                        <ResultIndicator className="project-card-result-indicator" result={allPassed ? 1 : (totalFailed > 0 ? 0 : (totalScheduled > 0 ? 'scheduled' : null))} />

                        <Typography variant="h6" component="div" align="center" style={{ marginBottom: '10px' }}>
                            {name}
                        </Typography>

                        <Grid container className="project-card-result-totals" spacing={2} justifyContent="center" style={{ marginTop: '10px' }}>
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1">
                                    <ResultIndicator result={1} />
                                </Typography>
                                <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                                    {totalPassed}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1">
                                    <ResultIndicator result={0} />
                                </Typography>
                                <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                                    {totalFailed}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center', display: 'none' }}>
                                <Typography variant="subtitle1">
                                    <ResultIndicator result={'scheduled'} />
                                </Typography>
                                <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                                    {totalScheduled}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            )}
        </>
    );
};

export default ProjectCard;