// src/components/AutoLogout.js
import { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import useUserStore from '../store/useUserStore';
import { useNavigate } from 'react-router-dom';

const AutoLogout = () => {
    const { isAuthenticated, logout } = useAuth0();
    const navigate = useNavigate();
    const lastLogin = useUserStore((state) => state.lastLogin);

    useEffect(() => {
        const checkForAutoLogout = () => {
            const now = new Date();
            const lastLoginDate = new Date(lastLogin);
            const diff = now - lastLoginDate; // Difference in milliseconds
            const hoursSinceLastLogin = diff / (1000 * 60 * 60); // Convert to hours

            if (hoursSinceLastLogin >= 24) {
                logout({ returnTo: window.location.origin });
                //navigate('/'); // Optionally navigate to home page or login page after logout
            }
        };

        if (isAuthenticated && lastLogin) {
            const intervalId = setInterval(checkForAutoLogout, 60000); // Check every minute
            return () => clearInterval(intervalId); // Cleanup on component unmount
        }
    }, [isAuthenticated, lastLogin, logout, navigate]);

    return null; // This component does not render anything
};

export default AutoLogout;
