// src/components/CreateProjectDrawer.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Drawer, Button, IconButton, CircularProgress, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import ProjectForm from './ProjectForm';
import './EditProjectDrawer.css';
import useUserStore from '../../store/useUserStore';
import { createTestsForProject } from './ProjectWizard';
import { useProjects } from '../../hooks/useProjects';

const CreateProjectDrawer = ({ isOpen, onClose, setIsWizardUsed }) => {
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId, accessToken: state.accessToken }));
    const { getAccessTokenSilently } = useAuth0();
    const [formData, setFormData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const { data: projects, isLoading: isProjectsLoading, error: projectsError, refetch: projectsRefetch } = useProjects( appUserId);

    useEffect(() => {
        //always reset formData for this bad B
        if (isOpen) {
            setFormData({});
        }
    }, [isOpen]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const accessToken = await getAccessTokenSilently();
            const response = await axios.post(`${process.env.REACT_APP_ZENMODE_API_URL}/projects`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });

            onClose(); // Close the drawer
            projectsRefetch(); // Refetch the projects list

            if (formData.wizard_active) {
                // Proceed to create tests only if the user requested help with test creation
                await createTestsForProject(response.data._id, formData, appUserId, accessToken);
                setIsWizardUsed && setIsWizardUsed(true);
            }
            
        } catch (error) {
            console.error("Failed to create the project", error);
            const messages = error.response && error.response.data && error.response.data.details
                ? error.response.data.details
                : ['Failed to create the project due to an unexpected error.'];
            setErrorMessages(messages);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose} ModalProps={{ BackdropProps: { style: { backgroundColor: 'rgb(153 139 176 / 50%)' } } }}>
            <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
            </IconButton>
            <div className="edit-project-drawer-inner">
                <div className="inner-metrics">
                    <h3>Create Project</h3>
                    <Typography>Projects are categories that you can use to organize your tests...</Typography>
                </div>
                <div className="inner-config">
                    <h3>Create New Project</h3>
                    <form onSubmit={handleSubmit}>
                        <ProjectForm formData={formData} setFormData={setFormData} />
                        <div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ margin: 1 }}
                                startIcon={isSubmitting ? <CircularProgress color="inherit" size={20} /> : null}
                            >
                                Create Project
                            </Button>
                            <Button onClick={onClose} variant="outlined" color="secondary" sx={{ margin: 1 }}>
                                Close
                            </Button>
                        </div>
                        {errorMessages.length > 0 && (
                            <Alert severity="error" sx={{ margin: 1 }}>
                                <ul>
                                    {errorMessages.map((message, index) => (
                                        <li key={index}>{message}</li>
                                    ))}
                                </ul>
                            </Alert>
                        )}
                    </form>
                </div>
            </div>
        </Drawer>
    );
};

export default CreateProjectDrawer;
