import React from 'react';
import { useLocation } from 'react-router-dom';

const ProjectSelectorCard = ({ id, name, onClick }) => {
    const location = useLocation();
    const isActive = location.pathname === `/projects/${id}`;

    // Use template strings for dynamic class names
    const cardClassName = `project-selector-card ${isActive ? 'active' : ''}`;

    return (
        <div className={cardClassName}  onClick={() => onClick(id)}>
            <h4>{name}</h4>
        </div>
    );
};

export default ProjectSelectorCard;
