import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Table, TableBody, TableCell, TableRow, Switch, List, ListItem, Button } from '@mui/material';
import axios from 'axios';
import useUserStore from '../../store/useUserStore';
import { Download as DownloadIcon } from '@mui/icons-material';
import { exportCSV } from '../../utils/exportCSV';
import ChatInput from '../ChatInput';

const ResultCard = ({ _id, testId, summary, details, resultsRefetch }) => {
    const [isPassed, setIsPassed] = useState(details.pass === 1);
    const { appUserId, accessToken } = useUserStore(state => ({ appUserId: state.appUserId, accessToken: state.accessToken }));

    const initialQuery = `In 5 sentences or less, ${details.error_message}`;
    const [isAnalyzeMode, setIsAnalyzeMode] = useState(true);

    // Function to handle the API call
    const makeApiCall = async (url, data) => {
        return axios.put(url, data, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-User-Id': appUserId,
            },
        });
    };

    const handleSwitchChange = async (event) => {
        const newIsPassed = event.target.checked ? 1 : 0;
        const approvedError = newIsPassed ? details.error_message : '';

        try {
            await makeApiCall(`${process.env.REACT_APP_ZENMODE_API_URL}/tests/${testId}`, {
                config: { approved_error: approvedError }
            });

            await makeApiCall(`${process.env.REACT_APP_ZENMODE_API_URL}/results/${_id}`, {
                pass: newIsPassed,
            });

            setIsPassed(newIsPassed);
        } catch (error) {
            console.error('Error updating:', error);
        } finally {
            resultsRefetch();
        }
    };

    const handleExportCSV = (links) => {
        return () => {
            exportCSV(links, `link-scanner_${_id}.csv`);
        };
    };

    // Function to dynamically create the lists for broken and non-HTTPS links
    const renderLinkDetails = (linkData, label) => {
        if (linkData && linkData.length > 0) {
            return (
                <TableRow className="results-link-details">
                    <TableCell component="th" scope="row">{label}</TableCell>
                    <TableCell>
                        <List dense>
                            {linkData.map((item, index) => (
                                <ListItem key={index}>
                                    <Typography variant="body2">{item.url}</Typography>
                                    <div>
                                        <Typography variant="body2">Hosted on:</Typography>
                                        <List dense>
                                            {item.hostedUrls.map((hostedUrl, hostedIndex) => (
                                                <ListItem key={hostedIndex}>
                                                    <Typography variant="body2">{hostedUrl}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>

                                </ListItem>
                            ))}
                        </List>
                    </TableCell>
                </TableRow>
            );
        }
        return null;
    };

    return (
        <Card>
            <CardContent>
                <Table className="result-details-table" aria-label="Result details">
                    <TableBody>
                        {summary && (
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Summary</TableCell>
                                <TableCell>{summary}</TableCell>
                            </TableRow>
                        )}
                        {details.error_message && (
                            <>
                                <TableRow>
                                    <TableCell>Error Message</TableCell>
                                    <TableCell>{details.error_message}</TableCell>
                                </TableRow>
                                <TableRow className="mark-as-pass">
                                    <TableCell>Mark as Pass</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={!!isPassed}
                                            onChange={handleSwitchChange}
                                        />
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                        {details.video_url && (
                            <TableRow>
                                <TableCell>Video</TableCell>
                                <TableCell>
                                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ my: 2 }}>
                                        <video controls width="340" height="250" src={details.video_url}></video>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                        {details.screenshot_url && (
                            <TableRow>
                                <TableCell>Screenshot</TableCell>
                                <TableCell>
                                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ my: 2 }}>
                                        <img src={details.screenshot_url} />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                        {details.link_broken && details.link_broken.length > 0 && (
                            <TableRow>
                                <TableCell component="th" scope="row">Broken Links Detected</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        startIcon={<DownloadIcon />}
                                        onClick={handleExportCSV(details.link_broken)}
                                        sx={{ mt: 2 }}
                                    >
                                        Export CSV
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                        {details.link_non_https && details.link_non_https.length > 0 && (
                            <TableRow>
                                <TableCell component="th" scope="row">Broken Links Detected</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        startIcon={<DownloadIcon />}
                                        onClick={handleExportCSV(details.link_non_https)}
                                        sx={{ mt: 2 }}
                                    >
                                        Export CSV
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                        {details.unsafe && (
                            <TableRow>
                                <TableCell component="th" scope="row">Unsafe Links Detected</TableCell>
                                <TableCell>{details.unsafe}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {details.error_message && (
                    <ChatInput
                        initialQuery={initialQuery}
                        isAnalyzeMode={isAnalyzeMode}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default ResultCard;