import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Container,
    Button,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
} from '@mui/material';
import CreateTestCard from './Tests/TestCard/CreateTestCard';
import CreateTestDrawer from './Tests/CreateTestDrawer';
import CreateProjectCard from './Projects/ProjectCard/CreateProjectCard';
import CreateProjectDrawer from './Projects/CreateProjectDrawer';
// Import icons
import WebIcon from '@mui/icons-material/Web';
import BusinessIcon from '@mui/icons-material/Business';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SpeedIcon from '@mui/icons-material/Speed';
import TouchAppIcon from '@mui/icons-material/TouchApp';

const Welcome = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0); // 0: Project, 1: Test, 2: Success
    const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false);
    const [isTestDrawerOpen, setIsTestDrawerOpen] = useState(false);
    const [isWizardUsed, setIsWizardUsed] = useState(false);

    const handleNextStep = () => {

        if (isWizardUsed) {
            setCurrentStep(2); // Directly go to success if tests are created via the wizard
        } else {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const handleCreateNewProjectClose = () => {
        setIsProjectDrawerOpen(false);
        handleNextStep(); // Move to next step after closing project drawer
    };

    const handleCreateNewTestClose = () => {
        setIsTestDrawerOpen(false);
        handleNextStep(); // Move to next step after closing test drawer
    };

    const StepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <Typography variant="h4" gutterBottom textAlign="center">
                            Welcome!
                        </Typography>
                        <Typography variant="h6" textAlign="center">
                            Let's create your first project.
                        </Typography>
                        <Typography variant="body1" textAlign="center">
                            Projects are used to organize tests. Common examples include:
                        </Typography>
                        <List className="center-list">
                            <ListItem>
                                <ListItemIcon>
                                    <WebIcon />
                                </ListItemIcon>
                                A website or application name
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>
                                A client's business name
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <BuildIcon />
                                </ListItemIcon>
                                An internal project name
                            </ListItem>
                        </List>
                        <Box textAlign="center">
                            <CreateProjectCard onCardClick={() => setIsProjectDrawerOpen(true)} />
                            <CreateProjectDrawer isOpen={isProjectDrawerOpen} onClose={handleCreateNewProjectClose} setIsWizardUsed={setIsWizardUsed} />
                        </Box>
                    </>
                );
            case 1:
                if (isWizardUsed) {
                    handleNextStep();
                }
                return (
                    <>
                        <Typography variant="h4" gutterBottom textAlign="center">
                            Almost done...
                        </Typography>
                        <Typography variant="h6" textAlign="center">
                            Let's create your first test.
                        </Typography>
                        <Typography variant="body1" textAlign="center">
                            Tests are performed on an automated schedule to make sure that websites are working correctly. Common examples include:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                Uptime testing to ensure that a website is loading
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <TouchAppIcon />
                                </ListItemIcon>
                                Call-to-action testing to ensure that buttons are clickable/tappable
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <SpeedIcon />
                                </ListItemIcon>
                                PageSpeed testing to verify that the site is performing well
                            </ListItem>
                        </List>
                        <Box textAlign="center">
                            <CreateTestCard onCardClick={() => setIsTestDrawerOpen(true)} />
                            <CreateTestDrawer isOpen={isTestDrawerOpen} onClose={handleCreateNewTestClose} />
                        </Box>
                    </>
                );
            case 2:
                return (
                    <Box textAlign="center">
                        <CheckCircleIcon style={{ fontSize: 60 }} color="primary"/>
                        <Typography variant="h4" gutterBottom textAlign="center">
                            Setup Complete
                        </Typography>
                        {isWizardUsed ? (
                            <>
                                <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
                                    By selecting to automatically create all of the tests with your project, you created 8 tests with our recommended settings for most websites! Pretty easy huh?
                                </Typography>
                                <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
                                    Your first tests are all queued up to run. It may take a few minutes to start seeing results for some of the more complex tests, but you will see Up Time test and SSL test results pretty quickly.
                                </Typography>
                                <Typography variant="h5" gutterBottom textAlign="center">
                                    What's next?
                                </Typography>
                                <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
                                    Visit the Projects area to add/manage projects, and visit the tests area to create/manage tests:
                                </Typography>
                                <Button variant="contained" sx={{ mx: 1 }} onClick={() => navigate('/projects')}>
                                    Go to Projects
                                </Button>
                                <Button variant="outlined" sx={{ mx: 1 }} onClick={() => navigate('/tests')}>
                                    Go to Tests
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
                                    If you opted to create tests, they are all queued up and will start to produce results over the next few minutes.
                                </Typography>
                                <Typography variant="h5" gutterBottom textAlign="center">
                                    What's next?
                                </Typography>
                                <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
                                    Visit the Projects area to add/manage projects, and visit the tests area to create/manage tests:
                                </Typography>
                                <Button variant="contained" sx={{ mx: 1 }} onClick={() => navigate('/projects')}>
                                    Go to Projects
                                </Button>
                                <Button variant="outlined" sx={{ mx: 1 }} onClick={() => navigate('/tests')}>
                                    Go to Tests
                                </Button>

                            </>
                        )}
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Container className="welcome-page" maxWidth="sm">
            <Box sx={{ width: '100%', mb: 4 }}>
                <LinearProgress variant="determinate" value={(currentStep / 2) * 100} />
            </Box>
            <StepContent />
        </Container>
    );
};

export default Welcome;
