import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import useStripe from '../../hooks/useStripe';
import useUserStore from '../../store/useUserStore';
import PlanSelect from '../Account/PlanSelect';

const ManageSubscriptionButton = ({ flowData, buttonText = "Manage Subscription", className = "manage-subscription-button" }) => {
    const { appUser } = useUserStore();
    const { createCustomerPortalSession } = useStripe();
    const [manageSubscriptionButton, setManageSubscriptionButton] = useState(null);
    const [showPlanSelect, setShowPlanSelect] = useState(false);

    const togglePlanSelect = () => setShowPlanSelect(!showPlanSelect);

    const handleManageSubscription = async () => {
        const url = await createCustomerPortalSession(appUser.stripe_customer_id, appUser._id, flowData);
        console.log({url:url})
        if (url) {
            window.location.href = url;
        } else {
            alert('Failed to create customer portal session');
        }
    };

    useEffect(() => {
        if (appUser) {
            console.log(appUser)
            if (appUser?.stripe_customer_id) {
                setManageSubscriptionButton(
                    <Button variant="outlined" onClick={() => handleManageSubscription()} className={className}>
                        {buttonText}
                    </Button>
                );
            } else {
                setManageSubscriptionButton(
                    <>
                        <Button variant="outlined" onClick={togglePlanSelect} className={className}>
                            Get More Results
                        </Button>
                        <PlanSelect
                            open={showPlanSelect}
                            onClose={togglePlanSelect}
                        />
                    </>
                );
            }
        }
    }, [appUser, showPlanSelect]);

    return manageSubscriptionButton;
};

export default ManageSubscriptionButton;
