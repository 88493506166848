// usePlans.js
import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export const usePlans = ( appUserId) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery(['plans', appUserId], async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken && appUserId) {
            const response = await axios.get(`${process.env.REACT_APP_ZENMODE_API_URL}/plans`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });
            return response.data;
        }
        return [];
    }, {
        enabled: !!getAccessTokenSilently && !!appUserId,
        staleTime: 120000, // 2 minutes
    });
};