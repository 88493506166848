import React, { useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TableSortLabel, Skeleton } from '@mui/material';
import useUserStore from '../../store/useUserStore';
import { useResults } from '../../hooks/useResults';
import { useTests } from '../../hooks/useTests';
import { useProjects } from '../../hooks/useProjects';
import EditTestDrawer from '../Tests/EditTestDrawer';
import './ScheduleDataGrid.css';

const ScheduleDataGrid = () => {
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const { data: tests = [], isLoading: isTestsLoading, error: testsError, refetch: testsRefetch } = useTests(appUserId);
    const { data: results = [], isLoading: isResultsLoading, error: resultsError } = useResults(appUserId);
    const { data: projects = [], isLoading: isProjectsLoading, error: projectsError } = useProjects(appUserId);

    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc', table: 'tests' });
    const [currentTestDetails, setCurrentTestDetails] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleSortChange = (table, key) => {
        setSortConfig({
            key,
            table,
            direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
        });
    };

    const handleRowClick = (testIdForDrawer) => {
        const testDetails = tests.find(test => test._id === testIdForDrawer);
        setCurrentTestDetails(testDetails);
        setIsDrawerOpen(true);
        //navigate(`${window.location.pathname}?test_id=${testId}`);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
        //resultsRefetch();
        //navigate(`${window.location.pathname}?test_id=${testId}`);
    };

    const sortedTests = useMemo(() => {
        return tests.map(test => ({
            ...test,
            project: projects.find(p => p._id === test.project_id)?.name || 'Unknown',
            url: test.config.url,
            testName: test.name,
            date: new Date(test.next_run).toLocaleString(),
            result: 'Scheduled',
            testIdForDrawer: test._id
        })).sort((a, b) => (sortConfig.direction === 'asc' ? 1 : -1) * (new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])));
    }, [tests, projects, sortConfig]);

    const sortedResults = useMemo(() => {
        return results.map(result => {
            const associatedTest = tests.find(test => test._id === result.test_id);
            return {
                ...result,
                project: projects.find(p => p._id === associatedTest?.project_id)?.name || 'Unknown',
                url: associatedTest?.config.url || 'Unknown URL',
                testName: associatedTest?.name || 'Unknown Test',
                date: new Date(result.datetime).toLocaleString(),
                result: result.pass ? 'Pass' : 'Fail',
                testIdForDrawer: result.test_id
            };
        }).sort((a, b) => (sortConfig.direction === 'desc' ? 1 : -1) * (new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])));
    }, [results, tests, projects, sortConfig]);

    if (isTestsLoading || isResultsLoading || isProjectsLoading) {
        return (
            <div className="schedule-container">
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
            </div>
        )
    }

    if (testsError || resultsError || projectsError) {
        return <Typography>Error loading data: {testsError || resultsError || projectsError}</Typography>;
    }

    return (
        <div className="schedule-container">
            <Typography variant="h6" gutterBottom>Up Next</Typography>
            {/* Upcoming Tests Table */}
            <TableContainer component={Paper} style={{ marginBottom: 16 }}>
                <Table aria-label="upcoming tests table">
                    <TableHead>
                        <TableRow>
                            {['Date', 'Test Name', 'Project', 'URL', 'Status', 'Result'].map(column => (
                                <TableCell key={column}>
                                    <TableSortLabel
                                        active={sortConfig.key === column.toLowerCase() && sortConfig.table === 'tests'}
                                        direction={sortConfig.key === column.toLowerCase() ? sortConfig.direction : 'asc'}
                                        onClick={() => handleSortChange('tests', column.toLowerCase())}
                                    >
                                        {column}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTests.map(row => (
                            <TableRow key={row.id} hover onClick={() => handleRowClick(row.testIdForDrawer)}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.testName}</TableCell>
                                <TableCell>{row.project}</TableCell>
                                <TableCell>{row.url}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{row.result}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" gutterBottom>Past Results</Typography>
            {/* Past Results Table */}
            <TableContainer component={Paper}>
                <Table aria-label="past results table">
                    <TableHead>
                        <TableRow>
                            {['Date', 'Test Name', 'Project', 'URL', 'Status', 'Result'].map(column => (
                                <TableCell key={column}>
                                    <TableSortLabel
                                        active={sortConfig.key === column.toLowerCase() && sortConfig.table === 'results'}
                                        direction={sortConfig.key === column.toLowerCase() ? sortConfig.direction : 'asc'}
                                        onClick={() => handleSortChange('results', column.toLowerCase())}
                                    >
                                        {column}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedResults.map(row => (
                            <TableRow key={row.id} hover onClick={() => handleRowClick(row.testIdForDrawer)}>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.testName}</TableCell>
                                <TableCell>{row.project}</TableCell>
                                <TableCell>{row.url}</TableCell>
                                <TableCell>Completed</TableCell>
                                <TableCell>{row.result}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditTestDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} testDetails={currentTestDetails} refetchTests={testsRefetch} />
        </div>
    );
};

export default ScheduleDataGrid;
