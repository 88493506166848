import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const StackedBarChart = ({ data }) => {

    const options = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    // Callback to format tick labels, adding '%' only for specific values
                    callback: function (value) {
                        // Check if the value is 50 or 100
                        if (value === 50 || value === 100) {
                            return value + '%'; // Append a percentage sign to the label
                        }
                        return value; // For other values, return without '%' sign
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false  // This line hides the legend
            },
            tooltip: {
                callbacks: {
                    /*
                    title: function (tooltipItems) {
                        let title = tooltipItems[0].label;
                        //console.log(tooltipItems)
                        if (tooltipItems[0].dataIndex === data.labels.length - 1) { // Assuming the last index is for the predictive month
                            //console.log('its the prediction')
                            title += " (Prediction)";
                        }
                        return title;
                    }
                    */
                }
            }
        }
    };


    return <Bar className="results-timeline-chart" data={data} options={options}/>;
};

export default StackedBarChart;
