// src/config/formFieldOptions.js
export const frequencyIntervalUnitOptions = [
    { value: "minute", text: "Minutes" },
    { value: "hour", text: "Hours" },
    { value: "day", text: "Days" },
    { value: "week", text: "Weeks" },
    { value: "month", text: "Months" },
];

export const browserNameOptions = [
    { value: "Chrome", text: "Chrome" },
    { value: "Firefox", text: "Firefox" },
    { value: "Safari", text: "Safari" },
    { value: "Edge", text: "Edge" },
    { value: "Brave", text: "Brave" },
    { value: "Opera", text: "Opera" },
];

export const browserVersionOptions = [
    { value: "114", text: "114" },
    { value: "113", text: "113" },
    { value: "112", text: "112" },
    // Add more versions as needed
];

export const platformOptions = [
    { value: "Windows 11", text: "Windows 11" },
    { value: "Windows 10", text: "Windows 10" },
    { value: "macOS Sonoma", text: "macOS Sonoma" },
    // Add more platforms as needed
];

export const deviceNameOptions = [
    { value: "Desktop", text: "Desktop" },
    { value: "Galaxy S10 Plus", text: "Galaxy S10 Plus" },
    // Add more devices as needed
];

export const failureNotificationsOptions = [
    { value: "email_user", text: "Email me" },
    { value: "none", text: "Don't notify me" },
];