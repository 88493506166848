// useUsageStats.js
import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export const useUsageStats = (appUserId, planIds = []) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery(['usageStats', appUserId, planIds], async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken && appUserId) {
            const response = await axios.post(
                `${process.env.REACT_APP_ZENMODE_API_URL}/users/usage-stats`,
                planIds.length > 0 ? { plan_ids: planIds } : {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'X-User-Id': appUserId,
                    },
                }
            );
            return response.data;
        }
        return {};
    }, {
        enabled: !!getAccessTokenSilently && !!appUserId,
        staleTime: 60000, // 1 minute
    });
};
