import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { TextField, Button, CircularProgress } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // AI-style "magic" icon

import useUserStore from '../store/useUserStore';

const ChatInput = ({ initialQuery, isAnalyzeMode }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const [userQuery, setUserQuery] = useState(initialQuery);
    const [analyzeMode, setAnalyzeMode] = useState(isAnalyzeMode);
    const [conversation, setConversation] = useState([{ role: 'user', content: userQuery }]);
    const [questionsAsked, setQuestionsAsked] = useState(0);
    const [loading, setLoading] = useState(false);
    const [inputVisible, setInputVisible] = useState(false); // New state to control input visibility
    const endOfMessagesRef = useRef(null);
    const conversationRef = useRef(null);
    let conversationThread;

    const prependBrief = (content) => `In 5 sentences or less, ${content}`;
    const filterBriefFromDisplay = (content) => content.replace(/^In 5 sentences or less, /, '');

    useEffect(() => {
        if (conversationRef.current) {
            conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
        }
    }, [conversation]);

    const handleQuestionSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {

            if (questionsAsked !== 0) {
                conversationThread = [...conversation, { role: 'user', content: userQuery }]
            }
            
            const accessToken = await getAccessTokenSilently();
            const response = await axios.post(
                `${process.env.REACT_APP_ZENMODE_API_URL}/chats/completions`,
                {
                    messages: conversation,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'X-User-Id': appUserId,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const aiResponse = response.data.choices[0].message.content.trim();
            //console.log(aiResponse);

            if (questionsAsked === 0) {
                setConversation(convo => [...convo, { role: 'assistant', content: aiResponse }]);
            } else {
                setConversation(convo => [...convo, { role: 'user', content: userQuery }, { role: 'assistant', content: aiResponse }]);
            }

            setQuestionsAsked(questionsAsked + 1);
            setUserQuery('');
            setInputVisible(true); // Show the input field after the first submission
            setLoading(false);
            setAnalyzeMode(false);
        } catch (error) {
            console.error('Error submitting question to AI:', error);
            setLoading(false);
        }
    };

    return (
        <>
            {conversation.length > 1 && (
                <div ref={conversationRef} style={{ padding: '20px', maxHeight: '400px', overflowY: 'scroll' }}>
                    {conversation.map((message, index) => (
                        <div key={index} style={{ textAlign: message.role === 'user' ? 'right' : 'left', margin: '10px 0' }}>
                            <div style={{ display: 'inline-block', padding: '8px 15px', borderRadius: '20px', backgroundColor: message.role === 'user' ? '#007bff' : '#f1f1f1', color: message.role === 'user' ? 'white' : 'black' }}>
                            {message.role === 'user' ? filterBriefFromDisplay(message.content) : message.content}
                            </div>
                        </div>
                    ))}
                    <div ref={endOfMessagesRef} />
                </div>
            )}
            {inputVisible && (
                <form onSubmit={handleQuestionSubmit}>
                    <TextField
                        label="Ask additional questions about this error..."
                        variant="outlined"
                        fullWidth
                        value={userQuery}
                        onChange={(e) => setUserQuery(e.target.value)}
                        margin="normal"
                        disabled={loading || questionsAsked >= 5}
                        InputProps={{
                            rows: 1,
                            multiline: false,
                            inputComponent: 'textarea'
                        }}
                    />
                </form>
            )}
            <Button
                startIcon={loading ? <CircularProgress size={24} /> : <AutoFixHighIcon />}
                onClick={handleQuestionSubmit}
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading || (questionsAsked >= 5 && inputVisible)}
                style={{ marginTop: '10px' }}
            >
                {loading ? 'Analyzing...' : analyzeMode ? 'Analyze these results with AI' : 'Send'}
            </Button>
            <p>*All AI responses are unverified and can contain mistakes.</p>
            {questionsAsked >= 5 && (
                <div style={{ marginTop: '10px', color: 'red', textAlign: 'center' }}>
                    You have reached the maximum number of questions for this chat session.
                </div>
            )}
        </>
    );
};

export default ChatInput;
