import React from 'react';
import { Typography, Grid, Card } from '@mui/material';
import ResultIndicator from '../Results/ResultIndicator';
import './TestTypeCard.css';

const TestTypeCard = ({ testType, name, onCardClick, icon, shortDescription, results }) => {
    const totalPassed = results.filter(result => result.pass === 1).length;
    const totalFailed = results.filter(result => result.pass === 0).length;
    const totalScheduled = results.filter(result => result.pass !== 1 && result.pass !== 0).length;

    return (
        <Card className="test-type-card" onClick={() => onCardClick(testType)} sx={{ position: 'relative', borderRadius: '10px' }}>
            <div className="test-type-summary">
                {icon}

                <Typography variant="h6" component="div" align="center" style={{ marginBottom: '10px' }}>
                    {name}
                </Typography>

                <Typography variant="body2" align="center" style={{ marginBottom: '10px' }}>
                    {shortDescription}
                </Typography>

                <Grid container className="test-type-card-result-totals" spacing={2} justifyContent="center" style={{ marginTop: '0px' }}>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1">
                            <ResultIndicator result={1} />
                        </Typography>
                        <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                            {totalPassed}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1">
                            <ResultIndicator result={0} />
                        </Typography>
                        <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                            {totalFailed}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center', display: 'none' }}>
                        <Typography variant="subtitle1">
                            <ResultIndicator result={'scheduled'} />
                        </Typography>
                        <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                            {totalScheduled}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};

export default TestTypeCard;