import axios from 'axios';

export const createTestsForProject = async (projectId, formData, appUserId, accessToken) => {

    const tests = [
        {
            name: `${formData.name} Up Time Test`,
            type: "uptime",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "hour",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.homepage_url
            }
        },
        {
            name: `${formData.name} SSL Test`,
            type: "ssl",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "day",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.homepage_url,
                ssl_min_days_remaining: 14
            }
        },
        {
            name: `${formData.name} PageSpeed Test`,
            type: "pagespeed",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "week",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.homepage_url,
                min_score_mobile: "50",
                min_score_desktop: "70"
            }
        },
        {
            name: `${formData.name} Error Scanner Test`,
            type: "error_scanner",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "day",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.homepage_url
            }
        },
        {
            name: `${formData.name} Link Scanner Test`,
            type: "link_scanner",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "day",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.homepage_url
            }
        },
        {
            name: `${formData.name} CTA Clickability Test`,
            type: "cta",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "day",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.homepage_url,
                ctas: [
                    {
                        cta_text: formData.homepage_cta_text,
                        click: false,
                    }
                ]
            },
            environment: {
                platform_type: "Desktop",
                platform_name: "Windows 11",
                browser_name: "Chrome",
                browser_version: "latest"
            }
        },
        {
            name: `${formData.name} Form Fill Test`,
            type: "form_fill",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "day",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.contact_form_url
            },
            environment: {
                platform_type: "Desktop",
                platform_name: "Windows 11",
                browser_name: "Chrome",
                browser_version: "latest"
            }
        }
    ];

    //console.log(tests)

    // Conditionally add the Add to Cart Test if it's an ecommerce site
    if (formData.isEcommerce) {
        tests.push({
            name: `${formData.name} Add to Cart Test`,
            type: "add_to_cart",
            project_id: projectId,
            user_id: appUserId,
            frequency_interval_unit_type: "day",
            frequency_interval: 1,
            failure_notifications: "email_user",
            config: {
                url: formData.single_product_page_url,
                cart_url: formData.cart_url
            },
            environment: {
                platform_type: "Desktop",
                platform_name: "Windows 11",
                platform_type: "Desktop",
                platform_name: "Windows 11",
                browser_name: "Chrome",
                browser_version: "latest"
            }
        });
    }

    // Optionally handle each test creation in parallel
    const testPromises = tests.map(test =>
        axios.post(`${process.env.REACT_APP_ZENMODE_API_URL}/tests`, test, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-User-Id': appUserId,
            },
        }).then(() => {
            console.log(test);
        })
    );

    try {
        await Promise.all(testPromises);
        console.log("All tests created successfully");
    } catch (error) {
        console.error("Error creating tests", error);
    }
};
