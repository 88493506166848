import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, useMediaQuery, useTheme } from '@mui/material';

const TestSortingFilters = ({ selected, onChange }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <FormControl fullWidth>
            <InputLabel id="test-sorting-select-label">Sort by</InputLabel>
            <Select
                labelId="test-sorting-select-label"
                id="test-sorting-select"
                value={selected}
                label="sorting Filter"
                onChange={onChange}
                size={isMobile ? 'small' : 'medium'}
            >
                <MenuItem value="recently_created">Recently created</MenuItem>
                <MenuItem value="recent_result">Recent result</MenuItem>
                <MenuItem value="upcoming_schedule">Upcoming schedule</MenuItem>
            </Select>
        </FormControl>
    );
};

export default TestSortingFilters;
