import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { Box, CircularProgress } from '@mui/material';
import logo from '../img/zenmode-logo_star_black.svg';

export const AuthenticationGuard = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="calc(100vh - 100px)">
                <CircularProgress  className="circular-progress-no-bg"/>
                <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
                    <img width="400px" className="logo-loading" src={logo} alt="ZenMode Logo" />
                </Box>
            </Box>
        ),
    });

    /*return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="calc(100vh - 100px)">
            <CircularProgress />
            <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
                <img width="400px" className="logo-loading" src={logo} alt="ZenMode Logo" />
            </Box>
        </Box>
    );*/

    return <Component />;
};