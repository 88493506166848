import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SummaryPagespeed = ({ results }) => {
    const [mobileScore, setMobileScore] = useState(0);
    const [desktopScore, setDesktopScore] = useState(0);

    useEffect(() => {
        //console.log(results);
        const pagespeedResults = results.filter(result => result.test_type === 'pagespeed');
        if (pagespeedResults.length > 0) {
            const totalMobileScores = pagespeedResults.reduce((acc, curr) => acc + (curr.details.score_mobile || 0), 0);
            const totalDesktopScores = pagespeedResults.reduce((acc, curr) => acc + (curr.details.score_desktop || 0), 0);

            const averageMobileScore = Math.round(totalMobileScores / pagespeedResults.length);
            const averageDesktopScore = Math.round(totalDesktopScores / pagespeedResults.length);

            animateScore(0, averageMobileScore, setMobileScore);
            animateScore(0, averageDesktopScore, setDesktopScore);
        }
    }, [results]);

    const animateScore = (start, end, setter) => {
        if (start < end) {
            const duration = 1000; // Animation duration in milliseconds
            const stepTime = duration / end;
            let currentScore = start;
            const interval = setInterval(() => {
                currentScore++;
                setter(currentScore);
                if (currentScore === end) clearInterval(interval);
            }, stepTime);
        }
    };

    const getScoreClass = (score, isMobile = true) => {
        if (isMobile) {
            if (score >= 51) return 'score-good';
            if (score >= 21 && score <= 50) return 'score-average';
            return 'score-bad';
        } else {
            if (score >= 71) return 'score-good';
            if (score >= 41 && score <= 70) return 'score-average';
            return 'score-bad';
        }
    };

    return (
        <div className="average-pagespeed-scores">
            <Typography variant="h6">PageSpeed Averages</Typography>
            <div className="score">
                <div className={`score-value ${getScoreClass(mobileScore, true)}`}>{mobileScore}</div>
                <h4>Mobile</h4>
            </div>
            <div className="score">
                <div className={`score-value ${getScoreClass(desktopScore, false)}`}>{desktopScore}</div>
                <h4>Desktop</h4>
            </div>
        </div>
    );
};

export default SummaryPagespeed;
