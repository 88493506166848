import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from './components/AuthenticationGuard';
import AutoLogout from './components/AutoLogout';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Menus from './components/Menus/Menus';
import ProjectsSelector from './components/Projects/ProjectsSelector';
import Account from './views/Account';
import Summary from './views/Summary';
import Project from './views/Project';
import Schedule from './views/Schedule';
import Projects from './views/Projects';
import Tests from './views/Tests';
import CallbackPage from './components/Account/CallbackPage';
import Welcome from './components/Welcome';
import useUserStore from './store/useUserStore';
import { CircularProgress, Box, Typography } from '@mui/material';
import './App.css'; // For global styles

const App = () => {
    const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [isLoadingDatabaseUser, setIsLoadingDatabaseUser] = useState(true);
    const navigate = useNavigate();
    const fetchOrCreateUser = useUserStore((state) => state.fetchOrCreateUser);
    const setIsFirstLogin = useUserStore((state) => state.setIsFirstLogin);
    const { logout } = useAuth0();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            fetchOrCreateUser(getAccessTokenSilently, user, logout)
                .then(() => {
                    setIsLoadingDatabaseUser(false); // Ensure this happens regardless of subsequent conditions
                    if (useUserStore.getState().authError) {
                        setErrorMessage(useUserStore.getState().authError);
                        setTimeout(() => logout({ returnTo: process.env.REACT_APP_ZENMODE_APP_URL }), 4000); //this is somehow returning to localhost, needs to be the env specific logout url.
                    } else if (useUserStore.getState().isFirstLogin) {
                        console.log('Navigating to /welcome');
                        useUserStore.getState().setIsFirstLogin(false);
                        navigate('/welcome');
                    }
                })
                .catch((error) => {
                    console.error('Failed to fetch or create user:', error);
                    setIsLoadingDatabaseUser(false);
                    setErrorMessage("Your account is disabled or inactive. Please contact support@zenmode.ai for assistance.");
                    setTimeout(() => logout({ returnTo: process.env.REACT_APP_ZENMODE_APP_URL }), 4000);
                });
        } else {
            setIsLoadingDatabaseUser(false);
        }
    }, [isAuthenticated, fetchOrCreateUser, getAccessTokenSilently, user, /*isFirstLogin, setIsFirstLogin,*/ navigate, logout]);

    if (isLoading || isLoadingDatabaseUser) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress  className="circular-progress-no-bg"/>
            </Box>
        );
    }

    if (errorMessage || isLoadingDatabaseUser) {
        return (
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" minHeight="100vh">
                <Typography maxWidth="500px" align="center">{errorMessage}</Typography>
                <CircularProgress className="circular-progress-no-bg"/>
            </Box>
        );
    }

    return (
        <div className="app">
            <AutoLogout />
            <Menus />

            <div className="primary">
                <ProjectsSelector />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<AuthenticationGuard component={Summary} />} />
                        <Route path="/projects" element={<AuthenticationGuard component={Projects} />} />
                        <Route path="/projects/:id" element={<AuthenticationGuard component={Project} />} />
                        <Route path="/tests" element={<AuthenticationGuard component={Tests} />} />
                        <Route path="/tests/:id" element={<AuthenticationGuard component={Tests} />} />
                        <Route path="/schedule" element={<AuthenticationGuard component={Schedule} />} />
                        <Route path="/account" element={<AuthenticationGuard component={Account} />} />
                        <Route path="/welcome" element={<AuthenticationGuard component={Welcome} />} />
                        <Route path="/callback" element={<CallbackPage />} />
                        {/* Define other routes as needed */}
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default App;