import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import useUserStore from '../../store/useUserStore';
import { useProjects } from '../../hooks/useProjects';

import './Projects.css';
import ProjectSelectorCard from './ProjectSelectorCard';
import { useTheme, FormControl, InputLabel, MenuItem, Select, useMediaQuery } from '@mui/material';
import { Avatar } from '@mui/material';

const ProjectsSelector = () => {
    const { isAuthenticated, user } = useAuth0();
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const navigate = useNavigate();
    const location = useLocation();
    const { id: currentProjectId } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPhone = useMediaQuery(theme.breakpoints.down('ph'));
    const [selectedProject, setSelectedProject] = useState('');

    const { data: projects, isLoading: isProjectsLoading, error: projectsError, refetch: projectsRefetch } = useProjects( appUserId);

    useEffect(() => {
        setSelectedProject(currentProjectId || 'all');
    }, [currentProjectId, location.params]);

    const handleProjectChange = (event) => {
        const projectId = event.target.value;
        if (projectId === 'all') {
            setSelectedProject('');
            navigate(`/projects`);
        } else {
            setSelectedProject(projectId);
            navigate(`/projects/${projectId}`);
        }
    };

    const handleProjectClick = (projectId) => {
        navigate(`/projects/${projectId}`);
    };

    if (isProjectsLoading) {
        //return <div>Loading...</div>;
    }

    if (projectsError) {
        return <div>Error: {projectsError.message}</div>;
    }

    return (

        <>
            {isAuthenticated && (
                <div className="projects-selector">
                    {isMobile ? (
                        <>
                            <FormControl fullWidth>
                                <InputLabel sx={{ color: 'white' }}>Project</InputLabel>
                                <Select
                                    value={selectedProject}
                                    label="Select Project"
                                    onChange={handleProjectChange}
                                    size={isPhone ? 'small' : 'medium'}
                                    sx={{
                                        color: 'white',
                                        background: '#0b2031',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white',
                                        },
                                        '& .MuiSvgIcon-root': { // This targets the dropdown icon specifically
                                            color: 'white',
                                        }
                                    }}
                                    MenuProps={{
                                        classes: {
                                            paper: 'projects-selector-dropdown'  // Applying custom class to the dropdown
                                        }
                                    }}
                                >
                                    <MenuItem key='0' value='all' sx={{ color: 'white', backgroundColor: '#0b2031', '&:hover': { backgroundColor: '#0b2031' } }}>All Projects</MenuItem>

                                    {Array.isArray(projects) && projects.map(project => (
                                        <MenuItem key={project._id} value={project._id} sx={{ color: 'white', backgroundColor: '#0b2031', '&:hover': { backgroundColor: '#0b2031' } }}>{project.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <NavLink to="/account" className="project-selector-bar-avatar" >
                                <Avatar alt="Profile" src={user.picture} sx={{ width: 50, height: 50 }} />
                            </NavLink>
                        </>
                    ) : (
                        <>
                            <ProjectSelectorCard
                                key="0"
                                id="all"
                                name="All Projects"
                                onClick={() => navigate(`/projects`)}
                            />

                            {Array.isArray(projects) && projects.map(project => (
                                <ProjectSelectorCard
                                    key={project._id}
                                    id={project._id}
                                    name={project.name}
                                    onClick={() => handleProjectClick(project._id)}
                                />
                            ))}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default ProjectsSelector;
