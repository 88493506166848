// src/components/TestForm.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TextField, MenuItem, FormControl, InputLabel, Select, Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { testTypes } from '../../config/TestTypes';
import { frequencyIntervalUnitOptions, failureNotificationsOptions } from '../../config/FormFieldOptions';
import { getPlatformTypes, getPlatformNames, getBrowsersByPlatform, getBrowserVersions, getDevicesByPlatform } from './Platforms';
import useUserStore from '../../store/useUserStore';
import { useProjects } from '../../hooks/useProjects';


const TestForm = ({ formData, setFormData, onTestTypeChange, manageTestTypeSelection = false, formType = 'edit' }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const { data: projects, isLoading: isProjectsLoading, error: projectsError } = useProjects(appUserId);
    const [ctaConfigs, setCtaConfigs] = useState([]);
    const [formFillLabels, setFormFillLabels] = useState([]);
    const [filteredFrequencyIntervalUnitOptions, setFilteredFrequencyIntervalUnitOptions] = useState(frequencyIntervalUnitOptions);
    const [frequencyMin, setFrequencyMin] = useState();
    const [frequencyMax, setFrequencyMax] = useState();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeConfig = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                [name]: value,
            }
        }));
    };

    //Handle CTA configuration fields
    useEffect(() => {
        if (formData.config?.ctas && formData.config.ctas.length > 0) {
            setCtaConfigs(formData.config.ctas);
        } else {
            setCtaConfigs([{ cta_text: '', click: false }]);
        }
    }, [formData.config?.ctas]);

    const handleChangeCta = (index, event) => {
        const { name, value } = event.target;
        const newCtaConfigs = [...ctaConfigs];
        if (name === 'click') {
            newCtaConfigs[index][name] = event.target.checked;
        } else {
            newCtaConfigs[index][name] = value;
        }
        setCtaConfigs(newCtaConfigs);
        setFormData(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                ['ctas']: newCtaConfigs,
            }
        }));
    };

    const handleAddCtaConfig = () => {
        setCtaConfigs([...ctaConfigs, { cta_text: '', click: false }]);
    };

    const handleRemoveCtaConfig = (index) => {
        const newCtaConfigs = ctaConfigs.filter((_, i) => i !== index);
        setCtaConfigs(newCtaConfigs);
    };

    //Handle form_fill configuration fields
    useEffect(() => {
        if (formData.config?.form_fill_labels && formData.config.form_fill_labels.length > 0) {
            setFormFillLabels(formData.config.form_fill_labels);
        } else {
            setFormFillLabels([{ label: '' }]);
        }
    }, [formData.config?.form_fill_labels]);

    const handleChangeFormFillLabels = (index, event) => {
        const { name, value } = event.target;
        const newFormFillLabels = [...formFillLabels];
        newFormFillLabels[index][name] = value;

        setFormFillLabels(newFormFillLabels);
        setFormData(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                ['form_fill_labels']: newFormFillLabels,
            }
        }));
    };

    const handleAddFormFillLabels = () => {
        setFormFillLabels([...formFillLabels, { label: '' }]);
    };

    const handleRemoveFormFillLabels = (index) => {
        const newFormFillLabels = formFillLabels.filter((_, i) => i !== index);
        setFormFillLabels(newFormFillLabels);
    };

    const handleTypeChange = (event) => {
        const { name, value } = event.target;
        onTestTypeChange({ name, value });

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    useEffect(() => {

        //define the frequency_interval_unit_type options according to the test type
        let options;
        if (formData.type === 'uptime') {
            options = frequencyIntervalUnitOptions;
        } else if (formData.type === 'ssl') {
            options = frequencyIntervalUnitOptions.filter(option => option.value !== 'minute' && option.value !== 'hour');
        } else {
            options = frequencyIntervalUnitOptions.filter(option => option.value !== 'minute');
        }
        setFilteredFrequencyIntervalUnitOptions(options);

        if (!formData.environment) {
            //define the default environment or clear it out
            if (['form_fill', 'cta', 'add_to_cart'].includes(formData.type)) {
                setFormData(prevState => ({
                    ...prevState,
                    environment: {
                        ...prevState.environment,
                        platform_type: 'Desktop',
                        platform_name: 'Windows 11',
                        browser_name: 'Chrome',
                        browser_version: '124.0',
                        device_name: ''
                    }
                }));
            }
        }

    }, [formData.type]);


    useEffect(() => {

        //define the frequency_interval min/max and default option according to the test type

        let min = 1; // Default min if no valid unit type is found
        let max = 10; // Default max if no valid unit type is found

        console.log({ unit_type: formData.frequency_interval_unit_type });

        switch (formData.frequency_interval_unit_type) {
            case 'minute':
                min = 10;
                max = 30;
                break;
            case 'hour':
                min = 1;
                max = 12;
                break;
            case 'day':
                min = 1;
                max = 6;
                break;
            case 'week':
                min = 1;
                max = 3;
                break;
            case 'month':
                min = 1;
                max = 12;
                break;
            default:
                // Keep default min and max if no valid type is selected
                break;
        }

        setFrequencyMin(min);
        setFrequencyMax(max);

        /* Update the formData for frequency_interval only if the unit type is valid
        if (['minute', 'hour', 'day', 'week', 'month'].includes(formData.frequency_interval_unit_type)) {
            setFormData(prevState => ({
                ...prevState,
                frequency_interval: min,  // Set to the minimum value for the selected unit type
            }));
        } else {
            // Clear the frequency_interval if no valid unit type is selected
            setFormData(prevState => ({
                ...prevState,
                frequency_interval: null,
            }));
        }*/
    }, [formData.frequency_interval_unit_type]);


    // Function to render select options
    const renderProjectSelectOptions = (projects) => projects.map((project) => (
        <MenuItem key={project._id} value={project._id}>{project.name}</MenuItem>
    ));

    const getProjectName = (projectId, projects) => {
        const project = projects.find(p => p._id === projectId);
        return project ? project.name : '';
    };

    const getTestTypeName = (value, options) => {
        const testType = options.find(t => t.value === value);
        return testType ? testType.name : '';
    };

    useEffect(() => {
        // Only proceed if the name hasn't been set yet but project_id and type have
        if (!formData.name && formData.project_id && formData.type) {
            const projectName = getProjectName(formData.project_id, projects);
            const testTypeName = getTestTypeName(formData.type, testTypes);
            const newName = `${projectName} ${testTypeName} Test`;

            // Now, safely update formData with the newName
            setFormData(prevState => ({
                ...prevState,
                name: newName,
            }));
        }
    }, [formData, setFormData, projects, testTypes]);

    // New state for dynamic platform types, names, browsers, and versions
    const [platformTypes, setPlatformTypes] = useState([]);
    const [platformNames, setPlatformNames] = useState([]);
    const [browsers, setBrowsers] = useState([]);
    const [browserVersions, setBrowserVersions] = useState([]);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        // Fetch and set platform types
        const types = getPlatformTypes();
        setPlatformTypes(types);

        // If there's already a platform_type selected, fetch the corresponding platform names
        if (formData.environment && formData.environment.platform_type) {
            const initialPlatformType = formData.environment.platform_type;
            const names = getPlatformNames(initialPlatformType).map(item => item.platform);
            setPlatformNames(names);

            // If there's already a platform_name selected, fetch the corresponding browser options
            if (formData.environment.platform_name) {
                const initialPlatformName = formData.environment.platform_name;
                const browsers = getBrowsersByPlatform(initialPlatformType, initialPlatformName);
                setBrowsers(browsers);

                // If there's already a mobile platform_name selected, fetch the corresponding device options
                if (formData.environment.platform_name && formData.environment.platform_type === 'Mobile') {
                    const devices = getDevicesByPlatform(initialPlatformName);
                    setDevices(devices);
                }

                // If there's already a browser_name selected, fetch the corresponding browser versions, if on desktop
                if (formData.environment.browser_name && formData.environment.platform_type === 'Desktop') {
                    const initialBrowserName = formData.environment.browser_name;
                    const versions = getBrowserVersions(initialPlatformType, initialPlatformName, initialBrowserName);
                    setBrowserVersions(versions);
                }
            }
        }
    }, [formData.environment/*formData.environment.platform_type, formData.environment.platform_name, formData.environment.browser_name*/]); // Add dependencies as necessary


    // Handlers for each selection that update the options for the next selection
    const handlePlatformTypeChange = (event) => {
        const { value } = event.target;
        // Update formData including nested environment.platform_type correctly
        setFormData(prevState => ({
            ...prevState,
            environment: {
                ...prevState.environment,
                platform_type: value,
                platform_name: '',
                browser_name: '',
                browser_version: '',
                device_name: ''
            }
        }));

        // Proceed to update dependent fields like platform names, browsers, etc.
        const names = getPlatformNames(value).map(item => item.platform);
        setPlatformNames(names);
        setBrowsers([]);
        setBrowserVersions([]);
        setDevices([]);
    };

    const handlePlatformNameChange = (event) => {
        const name = event.target.value;
        setFormData(prevState => ({
            ...prevState,
            environment: {
                ...prevState.environment,
                platform_name: name,
                browser_name: '',
                browser_version: '',
                device_name: '' // Reset device_name if it's also dependent
            }
        }));

        // Assuming getBrowsersByPlatform is synchronous or has been awaited elsewhere
        const type = formData.environment.platform_type; // Use prevState for consistency
        const browserOptions = getBrowsersByPlatform(type, name);
        setBrowsers(browserOptions);
        setBrowserVersions([]);
        setDevices([]); // Consider resetting devices here if applicable
    };

    const handleBrowserChange = (event) => {
        const selectedBrowser = event.target.value;
        setFormData(prevState => ({
            ...prevState,
            environment: {
                ...prevState.environment,
                browser_name: selectedBrowser,
                browser_version: '' // Reset browser_version upon changing browser_name
            }
        }));

        if (formData.environment.platform === 'Desktop') {
            const versions = getBrowserVersions(formData.environment.platform_type, formData.environment.platform_name, selectedBrowser);
            setBrowserVersions(versions);
        }
    };

    // Function to handle browser version changes
    const handleBrowserVersionChange = (event) => {
        const browserVersion = event.target.value;
        setFormData(prevState => ({
            ...prevState,
            environment: {
                ...prevState.environment,
                browser_version: browserVersion
            }
        }));
    };

    // Function to handle changes for mobile devices
    const handleDeviceChange = (event) => {
        const deviceName = event.target.value;
        setFormData(prevState => ({
            ...prevState,
            environment: {
                ...prevState.environment,
                device_name: deviceName
            }
        }));
    };

    // Define similar handleChange functions for other fields as needed

    // Function to render select options
    const renderSelectOptions = (options) => options.map((option) => (
        <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
    ));

    return (
        <>

            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    {/* Assuming formData.type_fields.options is provided correctly */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Type</InputLabel>
                        <Select
                            name="type"
                            value={formData.type || ''}
                            onChange={handleTypeChange} // Use handleTypeChange here
                            label="Type"
                            disabled={formType === 'edit'}
                            required
                        >
                            {testTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Project</InputLabel>
                        <Select
                            name="project_id"
                            value={formData.project_id || ''}
                            onChange={handleChange}
                            label="Project"
                            disabled={formType === 'edit'}
                            required
                        >
                            {renderProjectSelectOptions(projects)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {/* Project ID */}
            {/* Implement similar logic for dynamic project options */}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    {/* URL */}
                    <TextField
                        fullWidth
                        margin="normal"
                        name="url"
                        label="Full URL"
                        placeholder="Full URL"
                        value={formData.config?.url || ''}
                        onChange={handleChangeConfig}
                    />

                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="name"
                        label="Name"
                        placeholder="Enter a Test Name"
                        value={formData.name || ''}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    {/* Frequency Interval */}
                    <TextField
                        fullWidth
                        margin="normal"
                        name="frequency_interval"
                        label="Run test every..."
                        type="number"
                        InputProps={{ inputProps: { min: frequencyMin, max: frequencyMax } }}
                        value={formData.frequency_interval || ''}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    {/* Frequency Interval Unit Type */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Frequency Unit</InputLabel>
                        <Select
                            name="frequency_interval_unit_type"
                            value={formData.frequency_interval_unit_type || ''}
                            onChange={handleChange}
                            label="Frequency Unit"
                        >
                            {renderSelectOptions(filteredFrequencyIntervalUnitOptions)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    {/* Failure Notifications */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Failure Notifications</InputLabel>
                        <Select
                            name="failure_notifications"
                            value={formData.failure_notifications || 'email_user'}
                            onChange={handleChange}
                            label="Failure Notifications"
                        >
                            {renderSelectOptions(failureNotificationsOptions)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {formData.config?.approved_error && (
                <div>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="approved_error"
                        label="Approved Error"
                        type="text"
                        placeholder="Approved error message from a result"
                        value={formData.config?.approved_error}
                        onChange={handleChangeConfig}
                    />
                    <Typography variant="body2" align="left">Delete this to remove error bypassing</Typography>
                </div>
            )}

            {['cta', 'form_fill', 'add_to_cart'].includes(formData.type) && (
                <Grid container spacing={2}>
                    {/* Platform Type (Desktop/Mobile) */}
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" component="div" style={{ marginTop: 20 }}>
                            Test Environment
                        </Typography>
                        <Typography variant="body2" style={{ marginBottom: 20 }}>Provide environment details in order from top to bottom, left to right</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Platform Type</InputLabel>
                            <Select label="Platform Type" value={formData.environment?.platform_type || ''} onChange={handlePlatformTypeChange}>
                                {platformTypes.map(type => (
                                    <MenuItem key={type} value={type}>{type}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Platform Name (Operating System for Desktop or Mobile Platform) */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Platform Name</InputLabel>
                            <Select
                                label="Platform Name"
                                value={formData.environment?.platform_name || ''}
                                onChange={handlePlatformNameChange}
                                disabled={!formData.environment?.platform_type}
                                sx={{ opacity: formData.environment?.platform_name ? 1 : 0.3 }}
                            >
                                {platformNames.map(name => (
                                    <MenuItem key={name} value={name}>{name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Conditional Rendering for Browser Name based on Platform Type */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Browser</InputLabel>
                            <Select
                                label="Browser"
                                value={formData.environment?.browser_name || ''}
                                onChange={handleBrowserChange}
                                disabled={!formData.environment?.platform_name || !formData.type == 'pagespeed'}
                                sx={{ opacity: formData.environment?.browser_name ? 1 : 0.3 }}
                            >
                                {browsers.map(browser => (
                                    <MenuItem key={browser} value={browser}>{browser}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Browser Version for Desktop */}
                    {formData.environment?.platform_type === 'Desktop' && (
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Browser Version</InputLabel>
                                <Select
                                    label="Browser Version"
                                    value={formData.environment?.browser_version || ''}
                                    onChange={handleBrowserVersionChange}
                                    disabled={!formData.environment?.browser_name}
                                    sx={{ opacity: formData.environment?.browser_version ? 1 : 0.3 }}
                                >
                                    {browserVersions.map(version => (
                                        <MenuItem key={version} value={version}>{version}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/* Conditional Rendering for Device Name based on Platform Type being Mobile */}
                    {formData.environment?.platform_type === 'Mobile' && (
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Device</InputLabel>
                                <Select
                                    label="Device"
                                    value={formData.environment?.device_name || ''}
                                    onChange={handleDeviceChange}
                                    disabled={!formData.environment?.platform_name}
                                    sx={{ opacity: formData.environment?.device_name ? 1 : 0.3 }}
                                >
                                    {devices.map(device => (
                                        <MenuItem key={device} value={device}>{device}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            )}

            <>
                {((formData.type === 'cta') || (formData.type === 'form_fill') || (formData.type === 'pagespeed') || (formData.type === 'ssl')) && (
                    <>
                        <Typography variant="h6" component="div" style={{ marginTop: 25 }}>
                            Test Configuration
                        </Typography>

                        {formData.type === 'cta' && (
                            <div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>CTA Text</TableCell>
                                            <TableCell>Click CTA?</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ctaConfigs.map((config, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        name="cta_text"
                                                        placeholder="Enter CTA Text"
                                                        value={config.cta_text}
                                                        onChange={(e) => handleChangeCta(index, e)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        name="click"
                                                        checked={config.click}
                                                        onChange={(e) => handleChangeCta(index, e)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleRemoveCtaConfig(index)}><DeleteIcon /></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Button startIcon={<AddIcon />} onClick={handleAddCtaConfig}>Add More CTAs</Button>
                            </div>
                        )}


                        {formData.type === 'form_fill' && (
                            <div>       
                                <Typography variant="body2" style={{ marginBottom: 20 }}>Enter the text labels for each field in the form. This text is located above each field, or in some cases, inside the field itself.</Typography>              
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Field Label</TableCell>
                                            <TableCell>Remove</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {formFillLabels.map((config, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        name="label"
                                                        placeholder="Enter the label text (Name, Email, etc)"
                                                        value={config.label}
                                                        onChange={(e) => handleChangeFormFillLabels(index, e)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton onClick={() => handleRemoveFormFillLabels(index)}><DeleteIcon /></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Button startIcon={<AddIcon />} onClick={handleAddFormFillLabels}>Add More Fields</Button>

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="success_message"
                                    label="Success Message"
                                    placeholder="Text that appears when form is filled out successfully"
                                    value={formData.config?.success_message || ''}
                                    onChange={handleChangeConfig}
                                    style={{ display: 'none' }}
                                />

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="form_selector"
                                    label="Form Selector"
                                    placeholder="CSS Selector"
                                    value={formData.config?.form_selector || ''}
                                    onChange={handleChangeConfig}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        )}

                        {formData.type === 'pagespeed' && (
                            <div>
                                {/* Min Mobile Score */}
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="min_score_mobile"
                                    label="Min Mobile Score"
                                    type="number"
                                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                                    value={formData.config?.min_score_mobile || ''}
                                    onChange={handleChangeConfig}
                                />

                                {/* Min Desktop Score */}
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="min_score_desktop"
                                    label="Min Desktop Score"
                                    type="number"
                                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                                    value={formData.config?.min_score_desktop || ''}
                                    onChange={handleChangeConfig}
                                />
                            </div>
                        )}

                        {formData.type === 'ssl' && (
                            <div>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="ssl_min_days_remaining"
                                    label="Minimum Days Until SSL Expiration"
                                    type="number"
                                    InputProps={{ inputProps: { min: 1, max: 365 } }} // Adjusted max to 365 for days in a year
                                    value={formData.config?.ssl_min_days_remaining || 1}
                                    onChange={handleChangeConfig}
                                />
                            </div>
                        )}
                    </>
                )}
            </>

        </>
    );
};

export default TestForm;
