import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, useMediaQuery, useTheme } from '@mui/material';

const TestResultFilters = ({ selected, onChange }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <FormControl fullWidth>
            <InputLabel id="test-result-select-label">Result</InputLabel>
            <Select
                labelId="test-result-select-label"
                id="test-result-select"
                value={selected}
                label="Result Filter"
                onChange={onChange}
                size={isMobile ? 'small' : 'medium'}
            >
                <MenuItem value="all">All Results</MenuItem>
                <MenuItem value="passed">Passed</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
                <MenuItem value="scheduled">Scheduled</MenuItem>
            </Select>
        </FormControl>
    );
};

export default TestResultFilters;
