import React from 'react';
import SummaryPagespeed from './SummaryPagespeed.js';
import SummaryResultsChart from './SummaryResultsChart.js';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@mui/material'; // Import MUI Button
import useUserStore from '../../store/useUserStore.js';
import { useResults } from '../../hooks/useResults.js';
import './Summary.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faClock } from '@awesome.me/kit-70027d07fa/icons/classic/solid';
import useTestOptionsStore from '../../store/useTestOptionsStore';

const SummaryResults = ({ projectId }) => {
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const setSelectedResult = useTestOptionsStore((state) => state.setSelectedResult);

    const handleNavigate = () => {
        setSelectedResult('failed');  // Set your selected result
        const scrollTarget = document.getElementById('main-tests-container');
        if (scrollTarget) {
            scrollTarget.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const options = {
        limitPerTest: '1',
        ...(projectId && { projectId }),
    };

    const {
        data: results,
        isLoading,
        error,
    } = useResults(appUserId, options);

    const allTestsPassed = results?.every(result => result.pass === 1);
    const someTestsFailed = results && results.length > 0 && !allTestsPassed;

    if (error) {
        console.error('Failed to fetch results:', error);
    }

    return (
        <div className="summary">
            {isLoading ? (
                <>
                    <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                    <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                </>
            ) : (
                <>
                    {results && results.length > 0 ? (
                        <>
                            {allTestsPassed ? (
                                <div className="summary-message">
                                    <FontAwesomeIcon icon={faCheckCircle} size="3x" className="success-icon" />
                                    <div>All of your most recent tests passed! Nice work. Now kick back and relax.</div>
                                </div>
                            ) : someTestsFailed && (
                                <div className="summary-message">
                                    <FontAwesomeIcon icon={faTimesCircle} size="3x" className="fail-icon" />
                                    <div>Some of your most recent tests failed. View the test results to learn more.</div>
                                    <Button variant="contained" onClick={handleNavigate} color="primary" style={{ marginTop: '10px' }}>
                                        View Failed Tests
                                    </Button>

                                </div>
                            )}

                            <SummaryResultsChart projectId={projectId} results={results} />
                            <SummaryPagespeed projectId={projectId} results={results} />
                        </>
                    ) : (
                        <div className="summary-message">
                            <FontAwesomeIcon icon={faClock} size="3x" />
                            <div>Create, schedule, and run tests to start tracking results.</div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SummaryResults;
