import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const useStripe = () => {
    const { getAccessTokenSilently } = useAuth0();

    const createCustomerPortalSession = async (stripeCustomerId, userId, flowData = null) => {

        console.log({
            stripe_customer_id: stripeCustomerId,
            return_url: window.location.origin,
            flow_data: flowData
        })

        //return null;

        try {
            const accessToken = await getAccessTokenSilently();
            const response = await axios.post(
                `${process.env.REACT_APP_ZENMODE_API_URL}/stripe/create-portal-session`,
                {
                    stripe_customer_id: stripeCustomerId,
                    return_url: window.location.origin,
                    flow_data: flowData
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'X-User-Id': userId,
                    }
                }
            );
            return response.data.url;
        } catch (error) {
            console.error('Error creating customer portal session:', error);
            return null;
        }
    };

    return { createCustomerPortalSession };
};

export default useStripe;
