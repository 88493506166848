import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import useTestOptionsStore from '../../store/useTestOptionsStore';

const SummaryResultsByTest = ({ results }) => {
    const [chartData, setChartData] = useState({
        labels: ['Pass', 'Fail'],
        datasets: [
            {
                label: 'Results',
                data: [0, 0],
                backgroundColor: [
                    '#3fbeb6',
                    '#ff3468',
                ],
            },
        ],
    });
    const setSelectedResult = useTestOptionsStore((state) => state.setSelectedResult);

    // Added state for passPercent
    const [passPercent, setPassPercent] = useState(0);

    useEffect(() => {
        let passCount = 0;
        let failCount = 0;

        results.forEach(result => {
            if (result.pass === 1) {
                passCount++;
            } else if (result.pass === 0) {
                failCount++;
            }
        });

        const totalCount = passCount + failCount;
        const newPassPercent = totalCount > 0 ? Math.round((passCount / totalCount) * 100) : 0;
        setPassPercent(newPassPercent);

        // Update chart data with counts
        setChartData(prevData => ({
            ...prevData,
            datasets: prevData.datasets.map(dataset => ({
                ...dataset,
                data: [passCount, failCount],
            })),
        }));
    }, [results]);

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'left',
            },
        },
        layout: {
            padding: 0,
        },
        onClick: (e, element) => {
            if (element.length > 0) {
                const index = element[0].index;
                // Based on the index, set the selectedResult
                if (chartData.labels[index] === 'Pass') {
                    setSelectedResult('passed');
                } else if (chartData.labels[index] === 'Fail') {
                    setSelectedResult('failed');
                }
                const scrollTarget = document.getElementById('main-tests-container');
                if (scrollTarget) {
                    scrollTarget.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },
    };

    return (
        <div className="test-results-overview">
            <Typography variant="h6">
                {passPercent}% of Tests Passed
            </Typography>
            <Doughnut data={chartData} options={options} />
        </div>
    );
};

export default SummaryResultsByTest;
