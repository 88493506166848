import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3fbeb6', // Custom green color
            contrastText: '#ffffff',
            light: '#757ce8',
            dark: '#002884'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: { // This will target all buttons
                    '&:hover': {
                        backgroundColor: 'black', // Set the hover color to black
                        color: '#fff'
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            ph: 600,
            sm: 1000,
            md: 1200,
            lg: 1500,
            xl: 1850,
        },
    },
});

export default theme;