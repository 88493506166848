import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export const useResults = (appUserId, options = {}) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery(['results', appUserId, options], async () => {
        const accessToken = await getAccessTokenSilently();
        if (!appUserId || !accessToken) {
            // Prevent execution if we don't have valid user details and access token
            return [];
        }

        let queryParams = new URLSearchParams();
        if (options.limitPerTest) {
            queryParams.append('limit_per_test', options.limitPerTest);
        }
        if (options.projectId) {
            queryParams.append('project_id', options.projectId);
        }
        if (options.testId) {
            queryParams.append('test_id', options.testId);
        }
        const queryString = queryParams.toString() ? `/results?${queryParams.toString()}` : '/results';

        try {
            const response = await axios.get(`${process.env.REACT_APP_ZENMODE_API_URL}${queryString}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });
            return response.data.data;
        } catch (error) {
            console.error('Error fetching results:', error);
            throw error;
        }
    }, {
        enabled: !!appUserId && !!getAccessTokenSilently, // This will ensure the query does not run until both are available
        staleTime: 60000, // 1 minute
    });
};
