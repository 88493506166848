import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faClock } from '@awesome.me/kit-70027d07fa/icons/classic/solid';
import Typography from '@mui/material/Typography';

const ResultIndicator = ({ result, className }) => {
    const getResultDetails = () => {
        if (result === 0) {
            return { icon: faTimesCircle, color: '#ff3468', text: 'Failed' };
        } else if (result === 1) {
            return { icon: faCheckCircle, color: '#3fbeb6', text: 'Passed' };
        }
        // Default case
        return { icon: faClock, color: '#000', text: 'Scheduled' };
    };

    const { icon, color, text } = getResultDetails();

    return (
        <Typography component="div" className={`${className} result-indicator`} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', color: color }}>
            <FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} />
            <div>{text}</div>
        </Typography>
    );
};

export default ResultIndicator;
