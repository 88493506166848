import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import ResultsUsageDetails from './ResultsUsageDetails';
import UsageStatsBar from '../Charts/UsageStatsBar';
import { useUsageStats } from '../../hooks/useUsageStats';
import useUserStore from '../../store/useUserStore';
import PlanSelect from '../Account/PlanSelect';
import './ResultsUsage.css';

const ResultsUsage = () => {
    const { appUser } = useUserStore((state) => ({ appUser: state.appUser }));
    const { data: usage, isLoading, isError } = useUsageStats(appUser?._id);
    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => setShowDetails(!showDetails);
    const [showPlanSelect, setShowPlanSelect] = useState(false);
    const togglePlanSelect = () => setShowPlanSelect(!showPlanSelect);
    const [showPlanSelectButton, setShowPlanSelectButton] = useState(false);
    const currentPlanUsage = usage?.usage_by_plan?.find(plan => plan._id === usage.current_plan) || {};

    useEffect(() => {
        if(currentPlanUsage?.used_percentage && currentPlanUsage?.scheduled_percentage) {
            if(Math.floor(currentPlanUsage.used_percentage + currentPlanUsage.scheduled_percentage) >= 90) {
                setShowPlanSelectButton(true);
            }
        }
    },[currentPlanUsage]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading usage stats</div>;
    }

    return (
        <>
            <Box className="results-usage" onClick={toggleDetails} style={{ cursor: 'pointer' }}>
                <ResultsUsageDetails
                    open={showDetails}
                    onClose={toggleDetails}
                    currentPlanUsage={currentPlanUsage}
                />
                <UsageStatsBar usedPercentage={currentPlanUsage.used_percentage} scheduledPercentage={currentPlanUsage.scheduled_percentage} />
                {showPlanSelectButton && (
                    <Button variant="contained" color="secondary" onClick={togglePlanSelect} style={{ marginTop: '10px' }} fullWidth>Upgrade</Button>
                )}
            </Box>

            <PlanSelect
                open={showPlanSelect}
                onClose={togglePlanSelect}
            />
        </>
    );
};

export default ResultsUsage;
