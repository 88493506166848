import React from 'react';
import TestTypesContainer from '../components/Tests/TestTypesContainer';
import TestsContainer from '../components/Tests/TestsContainer';
import { Typography } from '@mui/material';

const Tests = () => {
    return (
        <div className="dashboard">
            <Typography variant="h4" className="page-title fancy-font">Test Types</Typography>
            <Typography className="page-subtitle">Select a test type below to create new tests for that type.</Typography>
            <TestTypesContainer />
            <TestsContainer />
        </div>
    );
};

export default Tests;
