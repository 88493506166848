import React, { useState } from 'react';

import TestTypeIcon from '../TestTypeIcon';
import ResultIndicator from '../../Results/ResultIndicator';

import './TestCard.css';
import { Card, CardContent, Typography, IconButton, Box, CircularProgress } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import EnvironmentIcon from '../../EnvironmentIcon';
import Skeleton from '@mui/material/Skeleton';

const TestDetailCard = ({ _id, name, type, environment, config, onCardClick, onCopyClick, result, status, statusOverride }) => {
    const { isLoading } = useState(result);
    const getEnvironmentIcons = () => {
        const icons = [];
        for (const [key, value] of Object.entries(environment)) {
            icons.push(<EnvironmentIcon key={key} type={key} name={value} />);
        }
        return icons;
    };

    const handleCopyClick = (event) => {
        event.stopPropagation(); // Prevent card click
        onCopyClick(_id);
    };

    return (
        <>
            {isLoading ? (
                <Skeleton className="test-card" variant="rectangular" animation="wave" />
            ) : (
                <Card className="test-card" onClick={() => onCardClick(_id)} sx={{ position: 'relative', borderRadius: '10px' }}>
                    <Box sx={{ position: 'absolute', top: 8, right: 8, display: 'flex', gap: '8px' }}>
                        <IconButton className="edit-button" aria-label="edit" size="small" color="default" onClick={() => onCardClick(_id)}>
                            <EditOutlinedIcon />
                        </IconButton>
                        <IconButton aria-label="copy" size="small" onClick={handleCopyClick} color="default">
                            <FileCopyOutlinedIcon />
                        </IconButton>
                    </Box>
                    <CardContent className="test-card-innerwrap">
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="56px" className="test-result">
                            {statusOverride === 'queued' ? (
                                <Typography component="div">
                                    <ResultIndicator />
                                </Typography>
                            ) : (
                                status === 'running' ? (
                                    <Typography component="div">
                                        <CircularProgress size={36} color="secondary" className="test-running" />
                                        <div>Running</div>
                                    </Typography>
                                ) : (
                                    <ResultIndicator result={result?.pass} />
                                )
                            )}
                        </Box>
                        <Box className="test-summary">
                            <TestTypeIcon className="test-card-type" testType={type} />
                            <Typography variant="h6" component="div" className="test-card-title" sx={{ fontSize: '1rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {name}
                            </Typography>
                            {config && config.url && (
                                <Typography className="test-card-url" variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {config.url}
                                </Typography>
                            )}
                            <Box mt={2} display="flex" flexDirection="column" gap={1}>
                                {environment && (
                                    <Box display="flex" gap={1} flexWrap="wrap" className="environment-icons">
                                        {getEnvironmentIcons()}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default TestDetailCard;
