import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import io from 'socket.io-client';

import './EditTestDrawer.css';
import Drawer from '@mui/material/Drawer';
import { useTheme, useMediaQuery, Tabs, Tab, Box, Typography, CircularProgress, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import TestForm from './TestForm';
import ResultsTimeline from '../Results/ResultsTimeline';
import useUserStore from '../../store/useUserStore';
import useTestRunStore from '../../store/useTestRunStore';
import Alert from '@mui/material/Alert';

const EditTestDrawer = ({ isOpen, onClose, testDetails, testsRefetch }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const [formData, setFormData] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [socket, setSocket] = useState(null);

    //test-action-buttons
    const [alertContent, setAlertContent] = useState({});
    let alertTimeout;
    const [isSaving, setIsSaving] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isRunDisabled, setIsRunDisabled] = useState(false);
    const setTestRunCompleted = useTestRunStore((state) => state.setTestRunCompleted);
    const defaultSaveButtonText = "Save Changes";
    const defaultRunButtonText = "Run Test";
    const [saveButtonText, setSaveButtonText] = useState(defaultSaveButtonText);
    const [runButtonText, setRunButtonText] = useState(defaultRunButtonText);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // 'lg' is typically 1280px in Material-UI themes
    const [tabValue, setTabValue] = useState(0);

    //set the formdata once testDetails is ready
    useEffect(() => {
        if (testDetails) {
            setFormData(testDetails);

            if (testDetails.status === 'queued' || testDetails.status === 'running') {
                setIsRunning(true);
                setIsSaveDisabled(true);
                setIsRunDisabled(true);
                setRunButtonText("Test Running...");
                setAlertContent({
                    title: "The test is already queued or running. Complex tests can take several minutes to run, and the test may be behind several others in the queue. Please check back later to view the results",
                    type: "info"
                });
            }
        }
    }, [testDetails]);

    //if isOpen changes or testDetails change, fiddle with the socket
    useEffect(() => {
        // Create a new socket connection when the drawer opens
        if (isOpen) {
            console.log('its open somehow?')
            const newSocket = io(process.env.REACT_APP_ZENMODE_API_URL || 'http://localhost:3000');
            setSocket(newSocket);
            newSocket.on('testStatusUpdate', (data) => {
                if (data.testId === testDetails?._id) {
                    handleStatusUpdate(data);
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('testStatusUpdate');
                socket.disconnect();

                setIsSaving(false);
                setIsRunning(false);
                setIsSaveDisabled(false);
                setIsRunDisabled(false);
                setSaveButtonText(defaultSaveButtonText)
                setRunButtonText(defaultRunButtonText);
                setAlertContent({});
            }
        };
    }, [isOpen, testDetails]);

    // Handle all of the status changes from the socket
    const handleStatusUpdate = (data) => {
        console.log('made it here')
        switch (data.status) {
            case 'queued':
                setRunButtonText("Test Queued...");
                setIsRunning(true);
                setIsSaveDisabled(true);
                setIsRunDisabled(true);
                break;
            case 'running':
                setRunButtonText("Test Running...");
                setIsRunning(true);
                setIsSaveDisabled(true);
                setIsRunDisabled(true);
                break;
            case 'completed':
                setRunButtonText("Test Completed!");
                setIsRunning(false);
                setAlertContent({
                    title: "The test run was successful. Please review the latest result in the Results Timeline.",
                    type: "success"
                });
                alertTimeout = setTimeout(() => {
                    setRunButtonText(defaultRunButtonText);
                    setSaveButtonText(defaultSaveButtonText);
                    setIsSaveDisabled(false);
                    setIsRunDisabled(false);
                    setAlertContent({});
                }, 5000);
                break;
            default:
                setRunButtonText(defaultRunButtonText);
                setSaveButtonText(defaultSaveButtonText);
                setIsSaveDisabled(false);
                setIsRunDisabled(false);
                setIsRunning(false);
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setIsSaveDisabled(true);
        setAlertContent({});
        clearTimeout(alertTimeout);

        try {
            setSaveButtonText("Saving...");

            const { user_id, project_id, type, ...body } = formData; // Destructure `user_id` out of formData           
            const accessToken = await getAccessTokenSilently();
            await axios.put(`${process.env.REACT_APP_ZENMODE_API_URL}/tests/${formData._id}`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });

            onClose();
            testsRefetch();//here!
            setTabValue(0);

            setSaveButtonText("Success");
            setAlertContent({
                title: "Test saved successfully!",
                details: [],
                type: "success"
            });

            alertTimeout = setTimeout(() => {
                setSaveButtonText(defaultSaveButtonText);
                setAlertContent({});
                setIsSaveDisabled(false);
            }, 5000);
        } catch (error) {
            setSaveButtonText(defaultSaveButtonText);

            alertTimeout = setTimeout(() => {
                setIsSaveDisabled(false);
            }, 5000);

            const details = error.response && error.response.data && error.response.data.details
                ? error.response.data.details
                : ['Failed to save the test due to an unexpected error.'];

            setAlertContent({
                title: "Failed to update test",
                details: details,
                type: "fail"
            });

            console.error("Failed to update the test", error);
        } finally {
            setIsSaving(false);
        }
    };

    const runTest = async () => {
        setIsRunning(true);
        setRunButtonText("Activating Test...");

        try {
            const encodedTestId = encodeURIComponent(testDetails._id);
            const accessToken = await getAccessTokenSilently();
            await axios.post(`${process.env.REACT_APP_ZENMODE_API_URL}/tests/${encodedTestId}/queue`, {
                urgent: true,
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });
        } catch (error) {
            const details = error.response && error.response.data && error.response.data.details
                ? error.response.data.details
                : ['Failed to run the test due to an unexpected error.'];

            setAlertContent({
                title: "Failed to run test",
                details: details,
                type: "warning"
            });

            alertTimeout = setTimeout(() => { }, 5000);

            console.error("Failed to run the test", error);
        } finally {
            setTestRunCompleted(true);
        }
    };

    const handleDeleteTest = async () => {
        //console.log(testDetails._id)
        if (testDetails?._id) {
            const accessToken = await getAccessTokenSilently();
            if (accessToken && appUserId) {
                axios.delete(`${process.env.REACT_APP_ZENMODE_API_URL}/tests/${testDetails._id}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'X-User-Id': appUserId,
                    },
                })
                    .then(() => {
                        onClose();
                        setTabValue(0);
                        setOpenDeleteDialog(false);
                        testsRefetch();
                    })
                    .catch(error => console.error("Failed to delete the test", error));
            }
        }
    };

    const editTestFormArea = () => {
        return (
            <>
                <pre className="debug">{JSON.stringify(testDetails, null, 2)}</pre>
                <form onSubmit={handleSubmit}>
                    <Box className="test-action-buttons edit-test-action-buttons" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: 2, marginY: 1 }}>
                        <div className="run-test">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={runTest}
                                disabled={isRunDisabled}
                                fullWidth
                                startIcon={isRunning ? <CircularProgress color="inherit" size={20} /> : null}
                                sx={{
                                    ...(isRunDisabled && {
                                        bgcolor: '#000 !important', // Keeps the button fully opaque even when disabled
                                        color: '#fff !important', // Prevents the text color from changing
                                        '& .MuiButton-startIcon': {
                                            opacity: 1, // Ensures icons within the button also do not appear disabled
                                        },
                                        '&:hover': {
                                            bgcolor: '#000 !important' // Ensures the hover background color remains consistent
                                        }
                                    })
                                }}
                            >
                                {runButtonText}
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSaveDisabled}
                                startIcon={isSaving ? <CircularProgress color="inherit" size={20} /> : null}
                            >
                                {saveButtonText}
                            </Button>
                        </div>
                        <div>
                            <Button onClick={onClose} variant="outlined" color="secondary">
                                Close
                            </Button>
                        </div>
                    </Box>
                    {alertContent?.title && (
                        <Alert severity={alertContent?.type} sx={{ margin: 1 }}>
                            <Typography align="left">{alertContent.title}</Typography>
                            {Array.isArray(alertContent.details) && alertContent.details.length > 0 && (
                                <ul>
                                    {alertContent.details.map((message, index) => (
                                        <li style={{ textAlign: 'left' }} key={index}>{message}</li>
                                    ))}
                                </ul>
                            )}
                        </Alert>
                    )}
                    <TestForm formData={formData} setFormData={setFormData} />
                </form>
            </>
        );
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Drawer anchor="right"
                open={isOpen}
                onClose={onClose}
                ModalProps={{
                    BackdropProps: {
                        style: {
                            backgroundColor: 'rgb(153 139 176 / 50%)',
                        },
                    },
                }}
            >
                <IconButton
                    className="delete-button"
                    onClick={() => setOpenDeleteDialog(true)}
                >
                    <DeleteIcon />
                </IconButton>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    className="close-button"
                >
                    <CloseIcon />
                </IconButton>
                <div className="edit-test-drawer-inner">
                    {isMobile ? (
                        <Box sx={{ width: '100%' }}>
                            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="test tabs">
                                <Tab label="Results Timeline" />
                                <Tab label="Edit Test" />
                            </Tabs>
                            {tabValue === 0 && (
                                <div className="inner-metrics">
                                    {alertContent?.title && (
                                        <Alert severity={alertContent?.type} sx={{ margin: 1 }}>
                                            <Typography align="left">{alertContent.title}</Typography>
                                            {Array.isArray(alertContent.details) && alertContent.details.length > 0 && (
                                                <ul>
                                                    {alertContent.details.map((message, index) => (
                                                        <li style={{ textAlign: 'left' }} key={index}>{message}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Alert>
                                    )}
                                    <ResultsTimeline testDetails={testDetails} />
                                    <Box className="test-action-buttons edit-test-action-buttons" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: 2, marginY: 1 }}>
                                        <div className="run-test">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={runTest}
                                                disabled={isRunDisabled}
                                                fullWidth
                                                startIcon={isRunning ? <CircularProgress color="inherit" size={20} /> : null}
                                                sx={{
                                                    ...(isRunDisabled && {
                                                        bgcolor: '#000 !important', // Keeps the button fully opaque even when disabled
                                                        color: '#fff !important', // Prevents the text color from changing
                                                        '& .MuiButton-startIcon': {
                                                            opacity: 1, // Ensures icons within the button also do not appear disabled
                                                        },
                                                        '&:hover': {
                                                            bgcolor: '#000 !important' // Ensures the hover background color remains consistent
                                                        }
                                                    })
                                                }}
                                            >
                                                {runButtonText}
                                            </Button>
                                        </div>
                                    </Box>
                                </div>
                            )}
                            {tabValue === 1 && (
                                <div className="inner-config">
                                    {editTestFormArea()}
                                </div>
                            )}
                        </Box>
                    ) : (
                        <>
                            <div className="inner-metrics">
                                <h3>Results Timeline</h3>
                                <ResultsTimeline testDetails={testDetails} />
                            </div>
                            <div className="inner-config">
                                <h3>Edit Test</h3>
                                {editTestFormArea()}
                            </div>
                        </>
                    )}
                </div>
            </Drawer>
            {/* Delete confirmation dialog */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="delete-modal"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Test"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Warning: this test and all associated results will be deleted.
                            <br /><b>This cannot be undone.</b></p>
                        <p>Are you sure that you want to delete this test?</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteTest} autoFocus>
                        Yes, delete test
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditTestDrawer;
