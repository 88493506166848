import React from 'react';
import ProjectsContainer from '../components/Projects/ProjectsContainer';
import { Typography } from '@mui/material';

const Projects = () => {
    return (
        <div className="dashboard">
                <Typography variant="h4" className="page-title fancy-font">Projects</Typography>
                <Typography className="page-subtitle">Projects are ways to organize your tests. They could be associated with a specific website or client, for example.</Typography>
                <ProjectsContainer />
        </div>
    );
};

export default Projects;
