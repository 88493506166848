import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import TestCard from './TestCard/TestCard.js';
import CreateTestCard from './TestCard/CreateTestCard.js';
import EditTestDrawer from './EditTestDrawer.js';
import CreateTestDrawer from './CreateTestDrawer.js';
import { Button, Typography, CircularProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TestTypeFilters from './TestTypeFilters.js';
import TestResultFilters from './TestResultFilters.js';
import TestSortingFilters from './TestSortingFilters.js';
import Box from '@mui/material/Box';

import useUserStore from '../../store/useUserStore';
import useTestOptionsStore from '../../store/useTestOptionsStore';

import { useResults } from '../../hooks/useResults.js';
import { useTests } from '../../hooks/useTests.js';

const TestsContainer = ({ projectId }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const [currentTestDetails, setCurrentTestDetails] = useState(null);
    const [isTestsRunning, setIsTestsRunning] = useState(false);
    const [testStatusOverride, setTestStatusOverride] = useState(null);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('test_id');

    const { data: tests, isLoading: isTestsLoading, error: testsError, refetch: testsRefetch } = useTests(appUserId, { projectId });
    const {
        //todo: results refresh doesn't need to occur here right?
        data: results,
        isLoading: isResultsLoading,
        error: resultsError,
        refetch: resultsRefetch
    } = useResults(appUserId, { limitPerTest: '1' }, {
        staleTime: 1 * 60 * 1000,
    });

    const { selectedTestType, setSelectedTestType, selectedResult, setSelectedResult, selectedTestSorting, setSelectedTestSorting } = useTestOptionsStore(state => ({
        selectedTestType: state.selectedTestType,
        setSelectedTestType: state.setSelectedTestType,
        selectedResult: state.selectedResult,
        setSelectedResult: state.setSelectedResult,
        selectedTestSorting: state.selectedTestSorting,
        setSelectedTestSorting: state.setSelectedTestSorting,
    }));

    const handleTestTypeChange = (event) => {
        if (handleTestTypeChange === null) {
            setSelectedTestType('all');
        } else {
            setSelectedTestType(event.target.value);
        }
    };

    const root = document.getElementById('root');

    const handleCardClick = (testId) => {
        const testDetails = tests.find(test => test._id === testId);
        setCurrentTestDetails(testDetails);
        setIsDrawerOpen(true);
        //navigate(`${window.location.pathname}?test_id=${testId}`);
    };

    const handleCreateNewTestClick = () => {
        setCurrentTestDetails(
            { project_id: projectId }
        );
        setIsCreateDrawerOpen(true); // Open the create drawer
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
        setIsCreateDrawerOpen(false);
        testsRefetch();
        resultsRefetch();
        //navigate(`${window.location.pathname}?test_id=${testId}`);
    };

    const handleCopyTestClick = (testId) => {
        const testDetails = tests.find(test => test._id === testId);
        setCurrentTestDetails(testDetails);
        setIsCreateDrawerOpen(true);
    };

    const runTests = async () => {
        const accessToken = await getAccessTokenSilently();
        if (filteredTests.length > 30 || filteredTests.length === 0) return;
        setIsTestsRunning(true);
        setTestStatusOverride('queued');

        const testIds = filteredTests.map(test => test._id);

        try {
            await axios.post(`${process.env.REACT_APP_ZENMODE_API_URL}/tests/schedule`, { tests: testIds }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });
        } catch (error) {
            console.error("Error scheduling tests:", error);
        }
    };

    useEffect(() => {
        let intervalId;

        if(isTestsRunning) {
           intervalId = setInterval(() => {
                if (!isDrawerOpen && !isCreateDrawerOpen) {
                    testsRefetch().then(setTestStatusOverride(null));
                    resultsRefetch();
                }
            }, 10000);
        }

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [isTestsRunning, isDrawerOpen, isCreateDrawerOpen, testsRefetch, resultsRefetch]);

    useEffect(() => {
        if (tests) {
            const isAnyTestRunning = tests.some(test => test.status === 'queued' || test.status === 'running');
            setIsTestsRunning(isAnyTestRunning);
        }
    }, [tests]);

    //reset filters on unmount
    useEffect(() => {
        return () => {
            setSelectedResult('all');
            setSelectedTestType(null);
        };
    }, []);

    const handleResultChange = (event) => {
        if (event.target.value === null) {
            setSelectedResult('all');
        } else {
            setSelectedResult(event.target.value);
        }
    };

    const handleTestSortingChange = (event) => {
        if (event.target.value === null) {
            setSelectedTestSorting('recently_created');
        } else {
            setSelectedTestSorting(event.target.value);
        }
    };

    const filteredTests = tests ? tests.filter(test => {
        //let's integrate selectedSorting in this area. Note that you can use the same method that is used to match recent result to the TestCard to sort by recent_result. recently_created is the default sorting. upcoming_schedule refers to the test's .next_invocation property and it should sort by the soonest to the latest.
        const testResult = results ? results.find(result => result.test_id === test._id) : undefined;
        const matchesType = selectedTestType === 'all' || test.type === selectedTestType;
        const matchesResult = selectedResult === 'all' ||
            (selectedResult === 'passed' && testResult?.pass === 1) ||
            (selectedResult === 'failed' && testResult?.pass === 0) ||
            (selectedResult === 'scheduled' && testResult === undefined);
        return matchesType && matchesResult;
    }) : [];

    const sortTests = (tests, results, sortingMethod) => {
        switch (sortingMethod) {
            case 'recently_created':
                return [...tests].sort((a, b) => {
                    // Sort tests that have a `created_at` date
                    if (a.created_at && b.created_at) {
                        return new Date(b.created_at) - new Date(a.created_at);
                    } else if (a.created_at) {
                        return -1; // a comes first
                    } else if (b.created_at) {
                        return 1; // b comes first
                    }
                    return 0; // keep original order if both lack `created_at`
                });
            case 'recent_result':
                // Pair tests with their results for sorting
                return [...tests].map(test => ({
                    ...test,
                    result: results.find(result => result.test_id === test._id)
                }))
                    // Sort based on the presence and datetime of the result
                    .sort((a, b) => {
                        if (a.result && b.result) {
                            return new Date(b.result.datetime) - new Date(a.result.datetime);
                        } else if (a.result) {
                            return -1;
                        } else if (b.result) {
                            return 1;
                        }
                        return 0;
                    })
                    // Unwrap the tests from the result pairing
                    .map(testWithResult => testWithResult);
            case 'upcoming_schedule':
                // Your existing logic for upcoming_schedule
                return [...tests].sort((a, b) => new Date(a.next_invocation) - new Date(b.next_invocation));
            default:
                return tests; // No sorting applied
        }
        return tests;
    };
    const filteredAndSortedTests = sortTests(filteredTests, results, selectedTestSorting);

    /*useEffect(() => {
        if (tests && id) {
            console.log('1')
            if (currentTestDetails) {
                console.log('2')
                setCurrentTestDetails(currentTestDetails);
                setIsDrawerOpen(true);
            }
        }
    }, [id, tests]);*/

    useEffect(() => {
        const handleGetTest = async (id) => {
            console.log('getting!')
            const accessToken = await getAccessTokenSilently();
            if (appUserId && accessToken) {
                const url = `${process.env.REACT_APP_ZENMODE_API_URL}/tests/${id}`;

                try {
                    const response = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'X-User-Id': appUserId,
                        },
                    });
                    console.log(response)
                    setCurrentTestDetails(response.data.data);
                    setIsDrawerOpen(true);
                } catch (error) {
                    console.error('Error fetching test by id:', error);
                    throw error;
                }
            }
        }
        if (id) {
            handleGetTest(id);
        }
    }, [id, appUserId]);

    if (isTestsLoading || isResultsLoading) {
        //return <div>Loading...</div>;
    }

    if (testsError || resultsError) {
        return <div>Error: {testsError?.message || resultsError?.message}</div>;
    }

    return (
        <div className="tests-container cards-container" id="main-tests-container">
            <div className="tests-container-controls">
                <Box className="tests-filter" sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2 }}>
                    <TestTypeFilters selected={selectedTestType} onChange={handleTestTypeChange} />
                    <TestResultFilters selected={selectedResult} onChange={handleResultChange} />
                    <TestSortingFilters selected={selectedTestSorting} onChange={handleTestSortingChange} />
                    <Box className="test-count" sx={{ flex: 1 }}>
                        Showing {filteredAndSortedTests.length} tests
                    </Box>
                </Box>
                <div className="run-tests-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
                    {filteredAndSortedTests.length <= 30 && !isTestsRunning && (
                        <Button variant="contained" color="primary" startIcon={<PlayArrowIcon />} onClick={runTests}>
                            Run Tests
                        </Button>
                    )}
                    {isTestsRunning && (
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled
                            startIcon={<CircularProgress color="inherit" size={20} />}
                        >
                            Tests Running...
                        </Button>
                    )}
                </div>
            </div>
            <CreateTestCard onCardClick={handleCreateNewTestClick} />
            {filteredAndSortedTests.map(test => (
                <TestCard
                    key={test._id}
                    {...test}
                    statusOverride={testStatusOverride}
                    onCardClick={handleCardClick}
                    result={results ? results.find(result => result.test_id === test._id) : undefined}
                    onCopyClick={() => handleCopyTestClick(test._id)}
                />
            ))}
            <EditTestDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} testsRefetch={testsRefetch} testDetails={currentTestDetails} />
            <CreateTestDrawer isOpen={isCreateDrawerOpen} onClose={handleCloseDrawer} testsRefetch={testsRefetch} testDetails={currentTestDetails} />
        </div>
    );
};

export default TestsContainer;