import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faFlask, faProjectDiagram, faCalendarAlt } from '@awesome.me/kit-70027d07fa/icons/classic/solid';
import './Menus.css';

const MobileBottomMenu = () => {
    const { pathname } = useLocation();
    const [value, setValue] = React.useState(pathname);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Define the menu items here
    const menuItems = [
        { label: 'Summary', icon: faHome, linkTo: '/' },
        { label: 'Tests', icon: faFlask, linkTo: '/tests' },
        { label: 'Projects', icon: faProjectDiagram, linkTo: '/projects' },
        { label: 'Schedule', icon: faCalendarAlt, linkTo: '/schedule' },
    ];

    return (
        <BottomNavigation value={value} onChange={handleChange} showLabels className="bottom-nav">
            {menuItems.map((item) => (
                <BottomNavigationAction
                    key={item.label}
                    label={item.label}
                    value={item.linkTo}
                    icon={<FontAwesomeIcon icon={item.icon} />}
                    component={Link}
                    to={item.linkTo}
                    className={`bottom-nav-button ${pathname === item.linkTo ? 'active' : ''}`}
                />
            ))}
        </BottomNavigation>

    );
};

export default MobileBottomMenu;
