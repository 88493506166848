import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandSparkles, faCheckCircle, faRocketLaunch, faUfo, faSpaceStationMoonConstruction } from '@awesome.me/kit-70027d07fa/icons/classic/solid';
import CloseIcon from '@mui/icons-material/Close';
import useUserStore from '../../store/useUserStore';
import { usePlans } from '../../hooks/usePlans';
import { useUsageStats } from '../../hooks/useUsageStats';
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import UsageStatsBar from '../Charts/UsageStatsBar';

const PlanSelect = ({ open, onClose }) => {
    const { appUserId, appUser } = useUserStore(state => ({ appUserId: state.appUserId, appUser: state.appUser }));
    const { data: plans, isLoading: isPlansLoading, error: plansError } = usePlans(appUserId);
    const [planIds, setPlanIds] = useState([]);
    const { data: usage, isLoading: isUsageLoading, isError: isUsageError } = useUsageStats(appUser?._id, planIds);
    const [structuredPlans, setStructuredPlans] = useState({});
    const [paymentUrls, setPaymentUrls] = useState({});
    const [planRenewalDate, setPlanRenewalDate] = useState("");

    useEffect(() => {
        if (appUserId && plans && appUser) {
            const newStructuredPlans = {};
            const newPaymentUrls = {};
            let planIdsForStats = [];

            plans.forEach(plan => {
                newStructuredPlans[plan.name] = plan;
                newPaymentUrls[plan.name] = `${plan.stripe_payment_link}?client_reference_id=${appUserId}`;

                if (appUser.stripe_subscription_id) {
                    newStructuredPlans[plan.name].flowData = {
                        type: 'subscription_update_confirm',
                        subscription_update_confirm: {
                            subscription: appUser.stripe_subscription_id,
                            items: [
                                {
                                    id: "si_Q7uDa7K9F11uFK",
                                    quantity: 1,
                                    price: plan.stripe_price_id,
                                }
                            ]
                        }
                    };
                }

                planIdsForStats.push(plan._id);
            });

            setPlanIds(planIdsForStats);
            setStructuredPlans(newStructuredPlans);
            setPaymentUrls(newPaymentUrls);
        }
    }, [appUserId, plans, appUser]);

    useEffect(() => {
        if (appUser?.subscription_period_end) {
            setPlanRenewalDate(new Date(appUser.subscription_period_end).toLocaleDateString('en-US'));
        }
    }, [appUser?.subscription_period_end]);

    if (isPlansLoading || (planIds.length > 0 && isUsageLoading)) {
        return <CircularProgress />;
    }

    if (plansError || isUsageError) {
        return <Typography variant="h6" color="error">Error loading plans or usage stats</Typography>;
    }

    const renderPlanCard = (planName) => {
        const plan = structuredPlans[planName];
        if (!plan) return null;

        let currentPlan = false;
        if (plan._id === appUser.plan) {
            currentPlan = true;
        }

        const usageStats = usage?.usage_by_plan?.find(stat => stat._id === plan._id) || {};

        return (
            <Card className="usage-type" key={plan._id}>
                <CardContent>

                    <div className="plan-icon-group">
                        {planName === "Basic" && (
                            <FontAwesomeIcon icon={faRocketLaunch} className="usage-type-icon" />
                        )}
                        {planName === "Premium" && (
                            <>
                                <FontAwesomeIcon icon={faUfo} className="usage-type-icon" />
                            </>
                        )}
                        {planName === "Agency" && (
                            <>
                                <FontAwesomeIcon icon={faSpaceStationMoonConstruction} className="usage-type-icon" />
                            </>
                        )}
                    </div>

                    <Typography variant="h4" align="center" className="fancy-font">{plan.name}</Typography>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <FontAwesomeIcon icon={faCheckCircle} color="#9b59b6" style={{ fontSize: "30px" }} />
                                </td>
                                <td>
                                    <Typography variant="body2" className="feature-row">{plan.usage_limits.standard_test_limits} Standard Test Results</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <FontAwesomeIcon icon={faCheckCircle} color="#9b59b6" style={{ fontSize: "30px" }} />
                                </td>
                                <td>
                                    <Typography variant="body2" className="feature-row">{plan.usage_limits.complex_test_limits} Complex Test Results</Typography>
                                </td>
                            </tr>
                            {plan.features.map((feature, index) => (
                                <tr key={index}>
                                    <td>
                                        <FontAwesomeIcon icon={faCheckCircle} color="#9b59b6" style={{ fontSize: "30px" }} />
                                    </td>
                                    <td>
                                        <Typography variant="body2" className="feature-row"> {feature}</Typography>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <UsageStatsBar
                        usedPercentage={usageStats.standard_used_percentage}
                        scheduledPercentage={usageStats.standard_scheduled_percentage}
                    />

                    {currentPlan ? (
                        <Button variant="text" fullWidth style={{ marginTop: "20px", color: "#fff", pointerEvents: "none" }}>Current Plan</Button>
                    ) : (
                        appUser.stripe_subscription_id ? (
                            <ManageSubscriptionButton flowData={plan.flowData} buttonText="Select Plan" className="plan-select-button" />
                        ) : (
                            <Button variant="outlined" href={paymentUrls[planName]} fullWidth style={{ marginTop: "20px", color: "#fff", borderColor: "#fff" }}>Select Plan</Button>
                        )
                    )}
                </CardContent>
            </Card>
        );
    };

    return (
        <Dialog onClose={onClose} className="results-usage-details-modal" aria-labelledby="customized-dialog-title" open={open} maxWidth="md" fullWidth onClick={(event) => event.stopPropagation()}>
            <DialogTitle>
                <Typography variant="h5" align="center">Select a Plan</Typography>
                <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box className="results-details-modal-inner pricing-table">
                    <Typography variant="subtitle1" align="center" className="subtitle">Pick the plan that accomodates your usage requirements:</Typography>
                    <Box display="flex" justifyContent="center" gap="1em">
                        {renderPlanCard('Basic')}
                        {renderPlanCard('Premium')}
                        {renderPlanCard('Agency')}
                    </Box>
                    <Typography variant="body1" style={{ marginTop: '20px', textAlign: 'center' }}>
                        {planRenewalDate ? (
                            <>
                                Your plan renews on {planRenewalDate}
                            </>
                        ) : (
                            <>
                                You are on the free plan, which does not renew.
                            </>                            
                        )}
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="center">
                        <Button color="inherit" onClick={onClose}>Close</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PlanSelect;
