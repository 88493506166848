import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export const useTests = (appUserId, options = {}) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery(['tests', options], async () => {
        const accessToken = await getAccessTokenSilently();
        if (appUserId && accessToken) {
            let queryParams = new URLSearchParams();

            if (options.projectId) {
                queryParams.append('project_id', options.projectId);
            }

            const queryString = queryParams.toString() ? `/tests?${queryParams.toString()}` : '/tests';

            try {
                const response = await axios.get(`${process.env.REACT_APP_ZENMODE_API_URL}${queryString}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'X-User-Id': appUserId,
                    },
                });
                console.log(response.data.data)
                return response.data.data;
            } catch (error) {
                console.error('Error fetching tests:', error);
                throw error;
            }
        }
        return [];
    }, {
        enabled: !!appUserId && !!getAccessTokenSilently,
    });
};