import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { TextField, Button, Box, Grid, FormControl } from "@mui/material";
import axios from 'axios';

const EditUserForm = ({ appUser, onCancel, onSuccess }) => {
    const [formData, setFormData] = useState({
        first_name: appUser.first_name,
        last_name: appUser.last_name,
        nickname: appUser.nickname
    });
    const { getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Update the user in your database
            const accessToken = await getAccessTokenSilently();
            const response = await axios.put(`${process.env.REACT_APP_ZENMODE_API_URL}/users`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUser._id // assuming _id is the user ID in your DB
                }
            });
            onSuccess(formData);
        } catch (err) {
            // If anything goes wrong, handle the error
            setError(err.message);
        }
    };

    /*const updateAuth0Email = async (newEmail) => {
        // Here you would use the Auth0 SDK to update the user's email
        // This is pseudocode and will need to be replaced with actual Auth0 API/SDK calls
        // return await auth0.updateUser({ email: newEmail });
    };*/

    // Your form goes here with TextField components for each field, a submit button, and a cancel button
    return (
        <Box component="form" className="edit-user-form" onSubmit={handleSubmit}>
            <TextField label="Email" name="email" disabled value={appUser.email} onChange={handleInputChange} fullWidth margin="normal" />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField label="First Name" name="first_name" value={formData?.first_name} onChange={handleInputChange} fullWidth margin="normal" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Last Name" name="last_name" value={formData?.last_name} onChange={handleInputChange} fullWidth margin="normal" />
                </Grid>
            </Grid>

            <TextField label="Nickname" name="nickname" value={formData?.nickname} onChange={handleInputChange} fullWidth margin="normal" />

            <Box className="account-buttons">
                <Button type="submit">Update Profile</Button>
                <Button onClick={onCancel}>Cancel</Button>
            </Box>
            {error && <Box>{error}</Box>}
        </Box>
    );
};

export default EditUserForm;
