// src/Platforms.js
import platformsData from '../../config/PlatformList.json';

// Function to get platform types (Desktop, Mobile)
export const getPlatformTypes = () => {
    return Object.keys(platformsData.platforms);
};

// Function to get platform names based on type (Desktop or Mobile)
export const getPlatformNames = (type) => {
    const platforms = platformsData.platforms[type] || [];
    // Ensure values are strings before comparing
    return platforms.sort((a, b) => a.platform.toString().localeCompare(b.platform.toString()));
};


// Function to get browsers based on platform type and platform name
export const getBrowsersByPlatform = (type, platformName) => {
    let browsers = [];

    if (type === 'Desktop') {
        // Assuming platformsData.platforms.Desktop is an array of platform objects
        const platform = platformsData.platforms.Desktop.find(p => p.platform === platformName);
        if (platform) {
            browsers = platform.browsers.map(browser => browser.browser_name);
        }
    } else if (type === 'Mobile') {
        if (platformName === 'Android') {
            browsers = ['Chrome', 'Firefox'];
        }
        else {
            browsers = ['Chrome', 'Safari', 'Firefox'];
        }
        /*
        // For Mobile, iterate over all devices within the selected platform to gather browsers
        // Assuming a similar structure for Mobile where browsers might be listed under devices
        const platform = platformsData.platforms.Mobile.find(p => p.platform === platformName);
        if (platform) {
          browsers = platform.devices.flatMap(device => device.browsers.map(browser => browser.browser_name));
        }*/
    }

    // Filter out unique browser names
    const uniqueBrowsers = [...new Set(browsers)];
    return uniqueBrowsers;
};

// Platforms.js
export const getBrowserVersions = (platformType, platformName, browserName) => {
    let versions = [];

    // Filter platforms based on the selected type (Desktop or Mobile)
    const platforms = platformsData.platforms[platformType] || [];

    platforms.forEach(platform => {
        // For Desktop, match by platform name; for Mobile, consider all under the type
        if (platformType === 'Desktop' && platform.platform === platformName) {
            platform.browsers.forEach(browser => {
                if (browser.browser_name === browserName) {
                    versions.push(browser.version); // Assuming 'version' is a singular value or an array
                }
            });
        } else if (platformType === 'Mobile' && platform.platform === platformName) {
            // Assuming a similar structure for Mobile, adjust if your structure differs
            platform.browsers.forEach(browser => {
                if (browser.browser_name === browserName) {
                    versions.push(browser.version); // Here too, adjust based on whether 'version' is singular or an array
                }
            });
        }
    });

    return [...new Set(versions)]; // Ensure uniqueness
};


// Function to get devices for Mobile platform type
export const getDevicesByPlatform = (platformName) => {
    if (platformName) {
        const devices = platformsData.platforms['Mobile'].find(p => p.platform === platformName).devices;
        // Use a Set to remove duplicates and then sort alphabetically
        const uniqueDeviceNames = [...new Set(devices.map(device => device.device_name))].sort((b, a) => a.localeCompare(b));
        return uniqueDeviceNames;
    }
    return [];
};