import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard/ProjectCard.js';
import CreateProjectCard from './ProjectCard/CreateProjectCard.js';
import CreateProjectDrawer from './CreateProjectDrawer.js';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../store/useUserStore';
import { useProjects } from '../../hooks/useProjects';
import { useResults } from '../../hooks/useResults';

const ProjectsContainer = () => {
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const { data: projects, isLoading: isProjectsLoading, error: projectsError, refetch: projectsRefetch } = useProjects( appUserId);
    const { data: results, isLoading: isResultsLoading, error: resultsError, refetch: resultsRefetch } = useResults( appUserId, { limitPerTest: '1' });

    const root = document.getElementById('root');
    useEffect(() => {
        root.classList.toggle('drawer-open', isCreateDrawerOpen);
    }, [isCreateDrawerOpen]);

    const handleCardClick = (projectId) => {
        navigate(`/projects/${projectId}`);
    };

    const handleCreateNewProjectClick = () => {
        setIsCreateDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsCreateDrawerOpen(false);
    };

    const getProjectResults = (projectId) => {
        return results?.filter(result => result.project_id === projectId) || [];
    };

    if (isProjectsLoading) {
        //return <div>Loading...</div>;
    }

    if (projectsError) {
        return <div>Error: {projectsError.message}</div>;
    }

    return (
        <div className="projects-container cards-container">
            <CreateProjectCard onCardClick={handleCreateNewProjectClick} />
            {Array.isArray(projects) && projects.map(project => (
                <ProjectCard
                    key={project._id}
                    {...project}
                    onCardClick={() => handleCardClick(project._id)}
                    results={isResultsLoading ? [] : getProjectResults(project._id)}
                    isLoading={isResultsLoading}
                />
            ))}
            <CreateProjectDrawer isOpen={isCreateDrawerOpen} onClose={handleCloseDrawer} />
        </div>
    );
};

export default ProjectsContainer;