import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import Sidebar from './Sidebar';
import MobileBottomMenu from './MobileBottomMenu';
import './Menus.css';

const Menus = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {
                isMobile ? <MobileBottomMenu /> : <Sidebar />
            }
        </>
    );
};

export default Menus;