import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@awesome.me/kit-70027d07fa/icons/classic/solid';
import './ProjectCard.css';

const CreateProjectCard = ({ onCardClick }) => {

    return (
        <div className="project-card create-new" onClick={onCardClick}>
            <FontAwesomeIcon icon={faPlusCircle} />
            <h3>Create Project</h3>
        </div>
    );
};

export default CreateProjectCard;
