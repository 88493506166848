import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button, useTheme } from '@mui/material';
import StackedBarChart from '../Charts/StackedBarChart'; // Ensure this component is set up correctly

const ResultsTimelineChart = ({ results, testDetails }) => {
    const [view, setView] = useState('14 Days');
    const theme = useTheme();

    const prepareChartData = () => {
        const now = new Date();
        let labels = [];
        let counts = {};

        // Determine date range labels based on the view
        switch (view) {
            case '14 Days':
                for (let i = 13; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
                    const label = `${date.getMonth() + 1}/${date.getDate()}`;
                    labels.push(label);
                    counts[label] = { pass: 0, fail: 0, total: 0 };
                }
                //labels.push('Prediction'); // Optionally, add a prediction label
                break;
            case '2 Months':
                for (let i = 7; i >= 0; i--) {
                    const startOfWeek = new Date(now - i * 7 * 24 * 60 * 60 * 1000);
                    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
                    const endOfWeek = new Date(startOfWeek);
                    endOfWeek.setDate(endOfWeek.getDate() + 6);

                    const label = `${startOfWeek.getMonth() + 1}/${startOfWeek.getDate()} - ${endOfWeek.getMonth() + 1}/${endOfWeek.getDate()}`;
                    labels.push(label);
                    counts[label] = { pass: 0, fail: 0, total: 0 };
                }
                //labels.push('Prediction'); // Optionally, add a prediction label
                break;
            case 'Past Year':
                for (let i = 11; i >= 0; i--) {
                    const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
                    const label = `${date.getMonth() + 1}/${date.getFullYear()}`;
                    labels.push(label);
                    counts[label] = { pass: 0, fail: 0, total: 0 };
                }
                break;
        }

        // Aggregate results into counts
        if(Array.isArray(results) && results.length > 0) {
            results.forEach(result => {
                const resultDate = new Date(result.datetime);
                let label;

                // Determine the label based on the view
                switch (view) {
                    case '14 Days':
                        label = `${resultDate.getMonth() + 1}/${resultDate.getDate()}`;
                        break;
                    case '2 Months':
                        label = labels.find(weekLabel => {
                            if (weekLabel === 'Prediction') return false;
                            const [start, end] = weekLabel.split(' - ').map(d => {
                                const [month, day] = d.split('/');
                                return new Date(resultDate.getFullYear(), month - 1, day);
                            });
                            return resultDate >= start && resultDate <= end;
                        });
                        break;
                    case 'Past Year':
                        label = `${resultDate.getMonth() + 1}/${resultDate.getFullYear()}`;
                        break;
                }

                if (label && counts.hasOwnProperty(label)) {
                    if (result.pass) {
                        counts[label].pass += 1;
                    } else {
                        counts[label].fail += 1;
                    }
                    counts[label].total += 1;
                }
            });
        }

        // Calculate percentages for each label
        let datasets = [{
            label: 'Pass',
            data: [],
            backgroundColor: 'rgba(63, 190, 182, 1)',
        }, {
            label: 'Fail',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 1)',
        }];

        labels.forEach(label => {
            if (counts[label]) { // Check if counts[label] exists to prevent the error
                const { pass, fail, total } = counts[label];
                const passPercentage = total ? (pass / total) * 100 : 0;
                const failPercentage = total ? (fail / total) * 100 : 0;

                datasets[0].data.push(passPercentage);
                datasets[1].data.push(failPercentage);
            } else {
                // Handle the case where counts[label] might be undefined
                // For example, by pushing 0 percentages, or adjusting logic to ensure counts[label] is always defined
                datasets[0].data.push(0);
                datasets[1].data.push(0);
            }
        });

        return { labels, datasets };
    };



    // Assume StackedBarChart component accepts a prop `data` for the chart data
    const chartData = prepareChartData();

    return (
        <div className="results-timeline-chart-wrapper">
            <ButtonGroup variant="outlined" aria-label="outlined primary button group" size="small" className="results-timeline-chart-buttons">
                {['14 Days', '2 Months', 'Past Year'].map(option => (
                    <Button
                        key={option}
                        onClick={() => setView(option)}
                        className="results-timeline-chart-button"
                        style={{
                            borderColor: theme.palette.secondary.main,
                            color: view === option ? theme.palette.common.white : theme.palette.secondary.main,
                            backgroundColor: view === option ? theme.palette.secondary.main : "transparent",
                        }}
                    >
                        {option}
                    </Button>
                ))}
            </ButtonGroup>
            <StackedBarChart data={chartData} />
        </div>
    );
};

export default ResultsTimelineChart;