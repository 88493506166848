// store/useUserStore.js
import { create } from 'zustand'
import axios from 'axios'

const useUserStore = create((set) => ({
    appUser: null,
    appUserId: null,
    isFirstLogin: false,
    lastLogin: null,
    plan: {},
    usage: {},
    fetchOrCreateUser: async (getAccessTokenSilently, user, logout) => {
        try {
            const accessToken = await getAccessTokenSilently();
            let response;
            let updateLastLoginResponse;

            try {
                response = await axios.get(`${process.env.REACT_APP_ZENMODE_API_URL}/users/auth0`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'X-Auth0-Id': user.sub,
                    },
                });
                updateLastLoginResponse = await axios.put(`${process.env.REACT_APP_ZENMODE_API_URL}/users`, {
                    last_login: new Date()
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'X-User-Id': response.data._id,
                    },
                });
                set({ appUser: response.data, appUserId: response.data._id, isFirstLogin: false, lastLogin: updateLastLoginResponse.data.last_login });
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    console.log('user is new! Lets create them.');
                    const now = new Date();
                    const userDetails = { 
                        email: user.email,
                        auth0_id: user.sub,
                        last_login: now
                    };
                    response = await axios.post(`${process.env.REACT_APP_ZENMODE_API_URL}/users/create`, userDetails, {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    });
                    
                    set({ appUser: response.data, appUserId: response.data._id, isFirstLogin: true, lastLogin: now });
                } else {
                    set({ authError: "Your account is disabled or inactive. Please contact support@zenmode.ai for assistance." });
                    console.error("Error fetching or creating user:", error);
                }
            }
        } catch (error) {
            set({ authError: "Your login has expired or is invalid. Returning to login screen." });
            console.error("Error with getAccessTokenSilently:", error);
        }
    },
    setIsFirstLogin: (isFirstLogin) => set({ isFirstLogin }),
    updateAppUser: (updatedFields) => set((state) => {
        // Update appUser directly if it's not related to "plan" or "usage"
        if (updatedFields.hasOwnProperty('plan') || updatedFields.hasOwnProperty('usage')) {
            // Deconstruct to avoid directly mutating state
            const newState = { ...state };
            if (updatedFields.plan) {
                newState.plan = { ...state.plan, ...updatedFields.plan };
            }
            if (updatedFields.usage) {
                newState.usage = { ...state.usage, ...updatedFields.usage };
            }
            return newState;
        } else {
            // Update appUser directly for other fields
            const newState = { appUser: { ...state.appUser, ...updatedFields } };
            console.log({newState: newState})
            return { appUser: { ...state.appUser, ...updatedFields } };
        }
    }),
}));

export default useUserStore;
