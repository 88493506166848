import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@awesome.me/kit-70027d07fa/icons/classic/solid'
import './TestCard.css';

const CreateTestCard = ({ onCardClick }) => {

    return (
        <div className="test-card create-new" onClick={onCardClick}>
            <FontAwesomeIcon icon={faPlusCircle} />
            <h3>Create Test</h3>
        </div>
    );
};

export default CreateTestCard;
