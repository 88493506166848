import { create } from 'zustand'
import { getTestTypeByValue } from '../config/TestTypes'; // Adjust the import path as necessary

const useTestOptionsStore = create((set, get) => ({
    selectedTestType: 'all',
    selectedResult: 'all',
    selectedTestSorting: 'recently_created',
    selectedTestTypeObject: getTestTypeByValue('all'), // Initialize with the 'all' type object
    setSelectedTestType: (testTypeValue) => {
        const resolvedTestType = typeof testTypeValue === 'string' && testTypeValue ? testTypeValue : 'all';
        const testTypeObject = getTestTypeByValue(resolvedTestType);
        set({
            selectedTestType: resolvedTestType,
            selectedTestTypeObject: testTypeObject || null,
        });
    },
    setSelectedResult: (selectedResult) => set({ selectedResult }),
    setSelectedTestSorting: (selectedTestSorting) => set({ selectedTestSorting }),
}));

export default useTestOptionsStore;
