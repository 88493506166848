import React from 'react';
import { Typography } from '@mui/material';
import ScheduleDataGrid from '../components/Schedule/ScheduleDataGrid';

const Schedule = () => {
    return (
        <div className="dashboard">
            <Typography variant="h4" className="page-title">Test Schedule</Typography>
            <ScheduleDataGrid />          
        </div>
    );
};

export default Schedule;
