// utils/exportCSV.js
export const exportCSV = (jsonData, filename = 'export.csv') => {
    if (!jsonData || !jsonData.length) {
        return;
    }

    // Dynamically determine the headers
    const headers = Object.keys(jsonData[0]);

    // Convert nested objects to JSON strings
    const convertToCSV = (obj) => {
        return headers.map(header => {
            const value = obj[header];
            return typeof value === 'object' && value !== null ? JSON.stringify(value) : value;
        }).join(',');
    };

    // Create CSV rows
    const csvRows = [];
    csvRows.push(headers.join(',')); // Add header row
    jsonData.forEach(row => {
        csvRows.push(convertToCSV(row));
    });

    // Create CSV file
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
