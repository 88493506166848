import React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import DynamicForm from '@mui/icons-material/DynamicForm';
import TroubleShoot from '@mui/icons-material/Troubleshoot';
import LinkIcon from '@mui/icons-material/Link';
import LanguageIcon from '@mui/icons-material/Language';
import LockIcon from '@mui/icons-material/Lock';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonitorHeart from '@mui/icons-material/MonitorHeart';

const TestTypeIcon = ({ testType, className = '' }) => {
    // Function to convert testType to logically cased string
    const formatTestTypeName = (testType) => {
        return testType
            .split('_')
            .map(word => word.toUpperCase() === word ? word : word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // Determine the icon and formatted name
    let Icon;
    switch (testType) {
        case 'pagespeed':
            Icon = <SpeedIcon color="secondary" />;
            break;
        case 'cta':
            Icon = <TouchAppIcon color="secondary" />;
            break;
        case 'uptime':
            Icon = <MonitorHeart color="secondary" />;
            break;
        case 'form_fill':
            Icon = <DynamicForm color="secondary" />;
            break;
        case 'error_scanner':
            Icon = <TroubleShoot color="secondary" />;
            break;
        case 'link_scanner':
            Icon = <LinkIcon color="secondary" />;
            break;
        case 'domain':
            Icon = <LanguageIcon color="secondary" />;
            break;
        case 'ssl':
            Icon = <LockIcon color="secondary" />;
            break;
        case 'add_to_cart':
            Icon = <ShoppingCartIcon color="primary" />;
            break;
        default:
            Icon = <SpeedIcon />;
            testType = 'default'; // Ensure testType has a value for formatting
    }

    const formattedName = formatTestTypeName(testType);

    return (
        <div className={`test-type-icon ${className}`}>
            {Icon}
            <div>{formattedName}</div>
        </div>
    );
};

export default TestTypeIcon;
