import React from 'react';
import { Box, Typography } from '@mui/material';

const UsageStatsBar = ({ usedPercentage, scheduledPercentage }) => {

    const totalPercentage = Math.floor(usedPercentage + scheduledPercentage);

    return (
        <>
            <Typography variant="body2" color="white" sx={{ textAlign: 'center', marginBottom: '10px' }}>Plan Usage {totalPercentage}%</Typography>

            <Box sx={{ width: '100%', bgcolor: '#ddd', borderRadius: '5px', overflow: 'hidden' }}>

                <Box sx={{ display: 'flex', height: '20px' }}>
                    <Box sx={{ width: `${usedPercentage}%`, bgcolor: '#ff3468' }} />
                    <Box sx={{ width: `${scheduledPercentage}%`, bgcolor: '#3fbeb6' }} />
                </Box>
            </Box>
        </>
    );
};

export default UsageStatsBar;
