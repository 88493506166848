import React, { useEffect, useState } from 'react';
import useUserStore from '../../store/useUserStore';
import useTestOptionsStore from '../../store/useTestOptionsStore';
import { useResults } from '../../hooks/useResults.js';
import TestTypeCard from './TestTypeCard';
import { testTypes } from '../../config/TestTypes';
import CreateTestDrawer from './CreateTestDrawer.js';

import { useTheme, useMediaQuery, Typography, IconButton, Grid, Button, Skeleton, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const TestTypesContainer = () => {
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const { selectedTestType, selectedTestTypeObject, setSelectedTestType } = useTestOptionsStore(state => state);
    const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {
        //todo: results refresh doesn't need to occur here right?
        data: results,
        isLoading: isResultsLoading,
        error: resultsError,
        refetch: resultsRefetch
    } = useResults(appUserId, { limitPerTest: '1' }, {
        staleTime: 1 * 60 * 1000,
    });

    const handleTestTypeChange = (testType) => {
        setSelectedTestType(testType.value);
    };

    const handleBackClick = () => {
        setSelectedTestType(null);
    };

    const handleCreateNewTestClick = () => {
        setIsCreateDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsCreateDrawerOpen(false);
    };

    const handleInstructionsToggle = () => {
        setIsInstructionsOpen(!isInstructionsOpen); // Toggle the instructions open state
    };

    if (isResultsLoading) {
        return (
            <div className="cards-container">
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
                <Skeleton className="styled-skeleton" variant="rectangular" animation="wave" />
            </div>
        );
    }

    if (resultsError) {
        return <div>Error: {resultsError.message}</div>;
    }

    const resultsByTestType = results ? results.reduce((acc, result) => {
        acc[result.test_type] = acc[result.test_type] ? [...acc[result.test_type], result] : [result];
        return acc;
    }, {}) : {};

    return (
        <div className="cards-container">
            {selectedTestTypeObject ? (
                <div className="test-type-details">
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={2} sm={1} className="test-type-back">
                            <IconButton onClick={handleBackClick} color="primary">
                                <ArrowBackIosNewIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                            <img className="test-type-graphic" src={`${process.env.PUBLIC_URL}/img/${selectedTestTypeObject.image}`} alt={selectedTestTypeObject.name} style={{ width: '100%', height: 'auto' }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" component="h3" gutterBottom>
                                {selectedTestTypeObject.name}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {selectedTestTypeObject.description}
                            </Typography>
                            {(!isMobile || isInstructionsOpen) && (
                                <>
                                    <Typography variant="h6" component="h4" gutterBottom>
                                        Instructions:
                                    </Typography>
                                    <Typography variant="body2">
                                        {selectedTestTypeObject.instructions}
                                    </Typography>
                                </>
                            )}
                            <Box className="test-type-buttons" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: 2, marginY: 1 }}>
                                <Button onClick={handleCreateNewTestClick} variant="contained" color="primary" sx={{ marginTop: "20px" }}>Create Test</Button>

                                {isMobile && (
                                    <Button onClick={handleInstructionsToggle} variant="outlined" sx={{ marginTop: "20px", color: "#fff", borderColor: "#fff" }}>
                                        {isInstructionsOpen ? "Hide" : "Instructions"}
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                testTypes.map((testType) => (
                    <TestTypeCard
                        key={testType.value}
                        testType={testType}
                        icon={testType.icon}
                        name={testType.name}
                        shortDescription={testType.short_description}
                        onCardClick={() => handleTestTypeChange(testType)}
                        results={resultsByTestType[testType.value] || []}
                    />
                ))
            )}
            <CreateTestDrawer
                isOpen={isCreateDrawerOpen}
                onClose={handleCloseDrawer}
                testDetails={{ type: selectedTestType }}
            />
        </div>
    );
};

export default TestTypesContainer;