import React from 'react';
import SummaryResults from '../components/Summary/SummaryResults';
import TestsContainer from '../components/Tests/TestsContainer';

const Summary = () => {
    return (
        <div className="dashboard">
            <SummaryResults />
            <TestsContainer />          
        </div>
    );
};

export default Summary;
