import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useProjects } from '../hooks/useProjects';

import { Box, Typography, TextField, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SummaryResults from '../components/Summary/SummaryResults';
import TestsContainer from '../components/Tests/TestsContainer';
import useUserStore from '../store/useUserStore';

const Project = () => {
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const { getAccessTokenSilently } = useAuth0();
    const [isEditActive, setIsEditActive] = useState(false);
    const [projectDetails, setProjectDetails] = useState(null);
    const [editName, setEditName] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: projects, isLoading: isProjectsLoading, error: projectsError, refetch: projectsRefetch } = useProjects( appUserId);

    useEffect(() => {
        fetchProject();
    }, [id, appUserId]);

    useEffect(() => {
        if (projectDetails) {
            setEditName(projectDetails.name);
        }
    }, [projectDetails]);

    const fetchProject = async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken && appUserId) {
            axios.get(`${process.env.REACT_APP_ZENMODE_API_URL}/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            })
                .then(response => {
                    setProjectDetails(response.data);
                })
                .catch(error => console.error("Failed to fetch project:", error));
        }
    };

    const handleSave = async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken && appUserId) {
            axios.put(`${process.env.REACT_APP_ZENMODE_API_URL}/projects/${id}`, { name: editName }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            })
                .then(() => {
                    fetchProject();
                    setIsEditActive(false);
                    projectsRefetch();
                })
                .catch(error => console.error("Failed to update the project", error));
        }
    };

    const handleEditClick = (e) => {
        e.stopPropagation(); // Prevent click from bubbling up
        setIsEditActive(true);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 100);
    };

    const handleOutsideClick = (e) => {
        if (inputRef.current && !inputRef.current.contains(e.target)) {
            setIsEditActive(false);
        }
    };

    useEffect(() => {
        if (isEditActive) {
            document.addEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isEditActive]);

    const handleCancel = () => {
        setIsEditActive(false);
        setEditName(projectDetails.name); // Revert changes if cancelled
    };

    const handleDeleteProject = async () => {
        const accessToken = await getAccessTokenSilently();
        if (accessToken && appUserId) {
            axios.delete(`${process.env.REACT_APP_ZENMODE_API_URL}/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            })
                .then(() => {
                    navigate('/projects');
                    projectsRefetch();
                })
                .catch(error => console.error("Failed to delete the project", error));
        }
    };

    return (
        <div className="dashboard">
            <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" className="page-title project-title fancy-font">
                    {!isEditActive ? (
                        isMobile ? (
                            <span>{projectDetails ? 'Project details' : 'Loading...'}</span>
                        ) : (
                            <span>{projectDetails ? projectDetails.name : 'Loading project details...'}</span>
                        )
                    ) : (
                        <TextField
                            size="small"
                            variant="outlined"
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                            inputRef={inputRef}
                        />
                    )}
                </Typography>

                {projectDetails && !isEditActive && (
                    <div className="edit-delete-project-buttons">
                        <IconButton onClick={handleEditClick} sx={{ border: '1px solid', ml: 1 }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => setOpenDeleteDialog(true)} sx={{ border: '1px solid', ml: 1 }}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )}
                {isEditActive && (
                    <div className="save-cancel-project-buttons">
                        <IconButton onClick={handleSave}>
                            <SaveIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                )}

            </Box>
            <SummaryResults projectId={id} />
            <TestsContainer projectId={id} />

            {/* Delete confirmation dialog */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="delete-modal"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Project"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Warning: all tests assigned to this project and the associated test results will be deleted. <b>This cannot be undone.</b></p>
                        <p>Are you sure that you want to delete this project?</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteProject} autoFocus>
                        Yes, delete project
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Project;
