import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { useTheme, useMediaQuery, Tabs, Tab, Drawer, Button, IconButton, Typography, CircularProgress, Card, CardActionArea, CardContent, Grid, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TestForm from './TestForm';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useUserStore from '../../store/useUserStore';
import { getTestTypeByValue, testTypes } from '../../config/TestTypes';
import useTestRunStore from '../../store/useTestRunStore';
import Alert from '@mui/material/Alert';

const CreateTestDrawer = ({ isOpen, onClose, testsRefetch, testDetails }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const [selectedTestType, setSelectedTestType] = useState(null);
    //test-action-buttons
    const [alertContent, setAlertContent] = useState({
        title: "The test will be scheduled to run immediately after it has been created",
        type: "info"
    });
    let alertTimeout;
    const [isSaving, setIsSaving] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isRunDisabled, setIsRunDisabled] = useState(false);
    const setTestRunCompleted = useTestRunStore((state) => state.setTestRunCompleted);
    const defaultSaveButtonText = "Create Test";
    const defaultRunButtonText = "Run Test";
    const [saveButtonText, setSaveButtonText] = useState(defaultSaveButtonText);
    const [runButtonText, setRunButtonText] = useState(defaultRunButtonText);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // 'lg' is typically 1280px in Material-UI themes
    const [tabValue, setTabValue] = useState(0);

    // Initialize formData state with an empty template or with testDetails for cloning
    const [formData, setFormData] = useState({
        type: '',
        failure_notifications: 'email_user',
    });

    //console.log(testDetails)

    useEffect(() => {
        // When cloning, initialize the form with the testDetails, otherwise use default values
        if (isOpen && testDetails) {
            setTabValue(1);
            const { _id, ...clonableDetails } = testDetails; // Exclude non-clonable properties
            setFormData(clonableDetails);
            // Optionally set the selected test type in the store if testTypes use such mapping
            const testType = getTestTypeByValue(testDetails.type);
            setSelectedTestType(testType || '');
        }
    }, [testDetails, isOpen, setSelectedTestType]);

    useEffect(() => {
        setIsSaving(false);
        setIsRunning(false);
        setIsSaveDisabled(false);
        setIsRunDisabled(false);
        setSaveButtonText(defaultSaveButtonText)
        setRunButtonText(defaultRunButtonText);
        setAlertContent({});
        clearTimeout(alertTimeout);
    }, [onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setIsSaveDisabled(true);
        setAlertContent({});
        clearTimeout(alertTimeout);

        try {
            setSaveButtonText("Saving...");
            const accessToken = await getAccessTokenSilently();
            const newTest = await axios.post(`${process.env.REACT_APP_ZENMODE_API_URL}/tests`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });

            if (newTest.data) {
                setFormData(newTest.data);
                setIsRunDisabled(false);
            }
            onClose(); // Run actions related to closure
            testsRefetch(); // Refresh the tests list

            setSaveButtonText("Success");
            setAlertContent({
                title: "Test created successfully!",
                details: [],
                type: "success"
            });

            alertTimeout = setTimeout(() => {
                setSaveButtonText(defaultSaveButtonText);
                setAlertContent({});
                setIsSaveDisabled(false);
            }, 5000);
        } catch (error) {
            setSaveButtonText(defaultSaveButtonText);

            alertTimeout = setTimeout(() => {
                setIsSaveDisabled(false);
            }, 5000);

            const details = error.response && error.response.data && error.response.data.details
                ? error.response.data.details
                : ['Failed to save the test due to an unexpected error.'];

            setAlertContent({
                title: "Failed to create test",
                details: details,
                type: "fail"
            });

            console.error("Failed to update the test", error);
        } finally {
            setIsSaving(false);
        }
    };

    const runTest = async () => {
        setIsRunning(true);
        setRunButtonText("Running Test...");
        setIsRunDisabled(true);
        setAlertContent({
            title: "The test may take up to 2 minutes to complete, depending on complexity. You may leave and check back later.",
            type: "info"
        });
        clearTimeout(alertTimeout);

        try {
            const accessToken = await getAccessTokenSilently();
            const encodedTestId = encodeURIComponent(testDetails._id);
            await axios.post(`${process.env.REACT_APP_ZENMODE_API_URL}/tests/${encodedTestId}/run`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-User-Id': appUserId,
                },
            });
            setRunButtonText("Completed");
            setAlertContent({
                title: "Test has been added to the immediate queue. Please check the Results Timeline to view the outcome.",
                type: "success"
            });

            alertTimeout = setTimeout(() => {
                setRunButtonText(defaultRunButtonText);
                setAlertContent({});
                setIsRunDisabled(false);
            }, 5000);
        } catch (error) {
            setRunButtonText(defaultRunButtonText);

            alertTimeout = setTimeout(() => {
                setIsRunDisabled(false);
            }, 5000);

            const details = error.response && error.response.data && error.response.data.details
                ? error.response.data.details
                : ['Failed to run the test due to an unexpected error.'];

            setAlertContent({
                title: "Failed to run test",
                details: details,
                type: "warning"
            });

            console.error("Failed to run the test", error);
        } finally {
            setIsRunning(false);
            setTestRunCompleted(true);
        }
    };

    // Update formData upon selecting a test type from the card
    const handleTestTypeClick = (option) => {
        setFormData({ ...formData, type: option.value });
        const testType = getTestTypeByValue(option.value);
        setSelectedTestType(testType);
        setTabValue(1);
    
        // Scroll to the top of the page
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };
    

    // Reset selection back to choosing a test type
    const resetSelection = () => {
        setSelectedTestType('');
        setFormData({ ...formData, type: '' });
    };

    const editTestFormArea = () => {
        return (
            <>
                <form onSubmit={handleSubmit}>
                    <TestForm
                        formData={formData}
                        setFormData={setFormData}
                        onTestTypeChange={handleTestTypeClick}
                        manageTestTypeSelection={!selectedTestType}
                        formType="create"
                    />
                    <Box className="test-action-buttons" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: 2, marginY: 1 }}>
                        <div className="run-test" hidden>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={runTest}
                                disabled={isRunDisabled}
                                fullWidth
                                startIcon={isRunning ? <CircularProgress color="inherit" size={20} /> : null}
                            >
                                {runButtonText}
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSaveDisabled}
                                startIcon={isSaving ? <CircularProgress color="inherit" size={20} /> : null}
                            >
                                {saveButtonText}
                            </Button>
                        </div>
                        <div>
                            <Button onClick={onClose} variant="outlined" color="secondary">
                                Close
                            </Button>
                        </div>
                    </Box>
                    {alertContent?.title && (
                        <Alert severity={alertContent?.type} sx={{ margin: 1 }}>
                            <Typography align="left">{alertContent.title}</Typography>
                            {Array.isArray(alertContent.details) && alertContent.details.length > 0 && (
                                <ul>
                                    {alertContent.details.map((message, index) => (
                                        <li style={{ textAlign: 'left' }} key={index}>{message}</li>
                                    ))}
                                </ul>
                            )}
                        </Alert>
                    )}
                </form>
            </>
        );
    }

    const testTypeDescriptionArea = () => {
        return (
            <>
                {selectedTestType ? (
                    <>
                        {selectedTestType.icon}
                        <Typography variant="h5" component="h3" style={{ textAlign: 'center', marginTop: '10px' }}>
                            {selectedTestType.name}
                        </Typography>
                        <Typography variant="h5" align="left">Description</Typography>
                        <Typography align="left">{selectedTestType.description}</Typography>
                        <Typography variant="h5" align="left">Instructions</Typography>
                        <Typography align="left">{selectedTestType.instructions}</Typography>
                        <IconButton onClick={resetSelection} color="primary" sx={{ marginTop: '30px' }}>
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <Typography variant="h5" component="h3">Make Sure Your Site is Working</Typography>
                        <Typography style={{ marginBottom: '20px' }}>Tests are run on schedule to verify that websites are functioning properly. Here are some descriptions of the various test types:</Typography>
                        {renderTestTypeCards(testTypes)}
                    </>
                )}
            </>
        );
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    // Render cards for choosing a test type
    const renderTestTypeCards = (options) => (
        <Grid container spacing={2} style={{ alignItems: 'stretch' }}>
            {options.map((option) => (
                <Grid item xs={6} key={option.value}>
                    <Card className="card-uniform-height create-test-type-cards">
                        <CardActionArea onClick={() => handleTestTypeClick(option)} className="card-content">
                            <CardContent style={{ textAlign: 'center' }}>
                                {option.icon}
                                <Typography variant="h6">{option.name}</Typography>
                                <Typography variant="body2">{option.short_description}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <Drawer anchor="right"
            open={isOpen}
            onClose={onClose}
            ModalProps={{
                BackdropProps: {
                    style: {
                        backgroundColor: 'rgb(153 139 176 / 50%)',
                    },
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                className="close-button"
            >
                <CloseIcon />
            </IconButton>
            <div className="edit-test-drawer-inner">
                {isMobile ? (
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="test tabs">
                            <Tab label="Test Types" />
                            <Tab label="Create Test" />
                        </Tabs>
                        {tabValue === 0 && (
                            <div className="inner-metrics create-test-types" style={{ padding: '20px' }}>
                                {testTypeDescriptionArea()}
                            </div>
                        )}
                        {tabValue === 1 && (
                            <div className="inner-config">
                                {editTestFormArea()}
                            </div>
                        )}
                    </Box>
                ) : (
                    <>
                        <div className="inner-metrics create-test-types" style={{ padding: '20px' }}>
                            {testTypeDescriptionArea()}
                        </div>
                        <div className="inner-config" style={{ padding: '20px' }}>
                            <h3>Create New Test</h3>
                            {editTestFormArea()}
                        </div>
                    </>
                )}
            </div>
        </Drawer >
    );
};

export default CreateTestDrawer;
