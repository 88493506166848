import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faWandSparkles, faCircle } from '@awesome.me/kit-70027d07fa/icons/classic/solid';
import CloseIcon from '@mui/icons-material/Close';
import UsageStatsBar from '../Charts/UsageStatsBar';
import PlanSelect from '../Account/PlanSelect';
import useUserStore from '../../store/useUserStore';

const Indicator = ({ color }) => (
    <CircularProgress size={14} thickness={5} style={{ color: color, marginRight: '5px' }} />
);

const ResultsUsageDetails = ({
    open,
    onClose,
    currentPlanUsage
}) => {
    const { appUser } = useUserStore(state => ({ appUser: state.appUser }));
    const [showPlanSelect, setShowPlanSelect] = useState(false);
    const togglePlanSelect = () => setShowPlanSelect(!showPlanSelect);
    const [usageMessage, setUsageMessage] = useState("With Zenmode, you pay for results.");
    const [planRenewalDate, setPlanRenewalDate] = useState("");
    useEffect(() => {
        if(currentPlanUsage.used_percentage >= 100) {
            setUsageMessage("Your plan limits have been reached. Upgrade to continue running tests.");
        }
        else if(currentPlanUsage.scheduled_percentage >= 100) {
            if(currentPlanUsage.used_percentage < 100) {
                setUsageMessage("You have scheduled more tests than you have available in your plan. Upgrade to avoid having tests paused.");
            }
        }
        if(appUser?.subscription_period_end) {
            setPlanRenewalDate(new Date(appUser.subscription_period_end).toLocaleDateString('en-US'));
        }
    },[currentPlanUsage, appUser]);

    console.log({currentPlanUsage:currentPlanUsage})

    return (
        <>
            <Dialog onClose={onClose} className="results-usage-details-modal" aria-labelledby="customized-dialog-title" open={open} maxWidth="md" fullWidth onClick={(event) => event.stopPropagation()}>
                <DialogTitle>
                    <Typography variant="h5" align="center">Your Current Usage Details</Typography>
                    <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8, color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box className="results-details-modal-inner">
                        <Typography variant="subtitle1" align="center" className="subtitle">{usageMessage}</Typography>
                        <Box display="flex" justifyContent="center" gap="1em">
                            {/* Standard Test Results */}
                            <Card className="usage-type">
                                <CardContent>
                                    <FontAwesomeIcon icon={faCheckCircle} className="usage-type-icon" />
                                    <Typography variant="subtitle1">Standard Test Results</Typography>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><Typography variant="body2"><FontAwesomeIcon icon={faCircle} color="#ff3468" /> Used</Typography></td>
                                                <td><Typography variant="body2">{currentPlanUsage?.standard_used}</Typography></td>
                                            </tr>
                                            <tr>
                                                <td><Typography variant="body2"><FontAwesomeIcon icon={faCircle} color="#3fbeb6" /> Scheduled</Typography></td>
                                                <td><Typography variant="body2">{currentPlanUsage?.standard_scheduled}</Typography></td>
                                            </tr>
                                            <tr>
                                                <td><Typography variant="body2"><FontAwesomeIcon icon={faCircle} /> Available</Typography></td>
                                                <td><Typography variant="body2">{currentPlanUsage?.standard_available}</Typography></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <UsageStatsBar
                                        usedPercentage={currentPlanUsage?.standard_used_percentage}
                                        scheduledPercentage={currentPlanUsage?.standard_scheduled_percentage}
                                    />
                                </CardContent>
                            </Card>

                            {/* Complex Test Results */}
                            <Card className="usage-type">
                                <CardContent>
                                    <FontAwesomeIcon icon={faWandSparkles} className="usage-type-icon" />
                                    <Typography variant="subtitle1">Complex Test Results</Typography>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><Typography variant="body2"><FontAwesomeIcon icon={faCircle} color="#ff3468" /> Used</Typography></td>
                                                <td><Typography variant="body2">{currentPlanUsage?.complex_used}</Typography></td>
                                            </tr>
                                            <tr>
                                                <td><Typography variant="body2"><FontAwesomeIcon icon={faCircle} color="#3fbeb6" /> Scheduled</Typography></td>
                                                <td><Typography variant="body2">{currentPlanUsage?.complex_scheduled}</Typography></td>
                                            </tr>
                                            <tr>
                                                <td><Typography variant="body2"><FontAwesomeIcon icon={faCircle} /> Available</Typography></td>
                                                <td><Typography variant="body2">{currentPlanUsage?.complex_available}</Typography></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <UsageStatsBar
                                        usedPercentage={currentPlanUsage?.complex_used_percentage}
                                        scheduledPercentage={currentPlanUsage?.complex_scheduled_percentage}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                        <Typography variant="body1" style={{ marginTop: '20px', textAlign: 'center' }}>
                            {planRenewalDate ? (
                                <>
                                    Your plan renews on {planRenewalDate}
                                </>
                            ) : (
                                <>
                                    You are on the free plan, which does not renew.<br/>Upgrade your plan to get more results each month.
                                </>                            
                            )}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button variant="contained" color="primary" onClick={togglePlanSelect} style={{ marginRight: '10px' }}>Upgrade</Button>
                            <Button variant="outlined" color="inherit" onClick={onClose}>Close</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            <PlanSelect
                open={showPlanSelect}
                onClose={togglePlanSelect}
            />
        </>
    );
};

export default ResultsUsageDetails;
