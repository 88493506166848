import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows, faApple, faAndroid, faChrome, faSafari, faFirefox } from '@awesome.me/kit-70027d07fa/icons/classic/brands';
import { faDesktop, faMobileAlt } from '@awesome.me/kit-70027d07fa/icons/classic/solid';

const EnvironmentIcon = ({ type, name }) => {
    let icon = ''; // Default icon

    if (type === 'platform_type') {
        icon = name.toLowerCase() === 'desktop' ? faDesktop : faMobileAlt;
    } else if (type === 'platform_name') {
        if (name.toLowerCase().includes('windows')) icon = faWindows;
        else if (name.toLowerCase().includes('ios') || name.toLowerCase().includes('osx') || name.toLowerCase().includes('mac os')) icon = faApple;
        else if (name.toLowerCase().includes('android')) icon = faAndroid;
    } else if (type === 'browser_name') {
        if (name.toLowerCase().includes('chrome')) icon = faChrome;
        else if (name.toLowerCase().includes('safari')) icon = faSafari;
        else if (name.toLowerCase().includes('firefox')) icon = faFirefox;
    } else {
        return;
    }

    if(icon) {
        return <FontAwesomeIcon icon={icon} />;
    }
};

export default EnvironmentIcon;
