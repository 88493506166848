import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { LogoutButton } from '../Account/LogoutButton';
import ResultsUsage from '../Results/ResultsUsage';
import logo from '../../img/zenmode-logo_star_white.svg';

const Sidebar = () => {
    const { isAuthenticated, user } = useAuth0();
    const activeStyle = {
        backgroundColor: '#9b59b6',
    };

    return (
        <>
        { isAuthenticated && (
            <nav className="sidebar">
                <ul>
                    <li>
                        <NavLink to="/"><img src={logo} alt="ZenMode Logo" /></NavLink>
                    </li>

                    <li className="menu-item">
                        <NavLink to="/" style={({ isActive }) => isActive ? activeStyle : undefined}>
                            Summary
                        </NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/tests" style={({ isActive }) => isActive ? activeStyle : undefined}>
                            Tests
                        </NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/projects" style={({ isActive }) => isActive ? activeStyle : undefined}>
                            Projects
                        </NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/schedule" style={({ isActive }) => isActive ? activeStyle : undefined}>
                            Schedule
                        </NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/account" style={({ isActive }) => isActive ? activeStyle : undefined}>
                            Account
                        </NavLink>
                    </li>
                    <li className="results-usage-wrapper">
                        <ResultsUsage />
                    </li>
                    <li className="sidebar-account">
                        <NavLink to="/account" style={({ isActive }) => isActive ? { ...activeStyle, borderRadius: '50%' } : undefined}>
                            <Avatar className="sidebar-avatar" alt="Profile" src={user.picture} sx={{ width: 50, height: 50 }} />
                        </NavLink>
                        <LogoutButton />
                    </li>
                </ul>
            </nav>
        )}
        </>
    );
};

export default Sidebar;
