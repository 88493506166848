import React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import DynamicForm from '@mui/icons-material/DynamicForm';
import TroubleShoot from '@mui/icons-material/Troubleshoot';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonitorHeart from '@mui/icons-material/MonitorHeart';

export const getTestTypeByValue = (value) => {
    return testTypes.find(testType => testType.value === value);
};

export const testTypes = [
    {
        value: "error_scanner",
        name: "Error Scanner",
        short_description: "Scan a webpage for errors using AI.",
        description: "This AI-driven test scans a designated webpage for errors using artificial intelligence, allowing the system to see your site as a user would.",
        instructions: "Configure the site URL in the form and let AI work its magic!",
        icon: <TroubleShoot className="test-type-icon" color="secondary" />,
        image: 'zenmode-default-graphic.png',
        complexity: 2,
    },
    {
        value: "cta",
        name: "CTA Clickability",
        short_description: "Verify that a call-to-action button is clickable.",
        description: "Specify a call-to-action button and verify that it is clickable / tappable on any device.",
        instructions: "In addition to defining the webpage url, you will want to specify the environment for the test. You will then enter the text of each call to action button in the 'CTA Text' fields, adding more as necessary. If you select 'Click CTA,' the will click the button and may end up navigating away from the page, so only use this on the last CTA on the page.",
        icon: <TouchAppIcon className="test-type-icon" color="secondary" />,
        image: 'zenmode_test-graphics_cta.png',
        complexity: 2,
    },
    {
        value: "add_to_cart",
        name: "Add to Cart",
        short_description: "Test the add to cart functionality on an eCommerce site.",
        description: "Verify that the user can interact with the add to cart form on an eCommerce website.",
        instructions: "Specify the URL you would like to scan. This test will find the add to cart form on this page, and attempt to add a product to the cart.",
        icon: <ShoppingCartIcon className="test-type-icon" color="secondary" />,
        image: 'zenmode_test-graphics_add_to_cart.png',
        complexity: 2,
    },
    {
        value: "pagespeed",
        name: "PageSpeed",
        short_description: "Measure the loading speed of a webpage.",
        description: "Ensure that your site loads quickly on desktop and mobile using the industry standard for performance testing.",
        instructions: "Specify the url to be tested along with the minimum desktop and mobile scores that you wish to achieve. The test will fail if it drops below either of these numbers.",
        icon: <SpeedIcon className="test-type-icon" color="secondary" />,
        image: 'zenmode_test-graphics_pagespeed.png',
        complexity: 1,
    },
    {
        value: "uptime",
        name: "Up Time",
        short_description: "Check if a website is accessible and running.",
        description: "This simple test ensures that your server is running. It is recommended that you run this at a high frequency.",
        instructions: "Specify the url - that's it! This test is very simple and meant to be run on a high frequency. Think of it like the heartbeat of your website...you want a heartbeat. Trust us.",
        icon: <MonitorHeart className="test-type-icon" color="secondary" />,
        image: 'zenmode_test-graphics_uptime.png',
        complexity: 1,
    },
    {
        value: "form_fill",
        name: "Form Fill",
        short_description: "Test the fillability and submission of a form.",
        description: "Ensure that key contact forms are fillable, and if they aren't, specify what went wrong.",
        instructions: "Specify the URL where the form is located. After submitting the form, specify how to verify a successful submission, such as checking for a success message. This could involve looking for specific name on the page or a URL redirection.",
        icon: <DynamicForm className="test-type-icon" color="secondary" />,
        image: 'zenmode_test-graphics_form_fill.png',
        complexity: 2,
    },
    {
        value: "ssl",
        name: "SSL Checker",
        short_description: "Verify the validity and expiration of an SSL certificate.",
        description: "Verify the validity and amount of time until the SSL expires.",
        instructions: "Specify the URL you would like to check for a valid SSL, and specify the minimum number of days remaining until expiration.",
        icon: <LockIcon className="test-type-icon" color="secondary" />,
        image: 'zenmode_test-graphics_ssl.png',
        complexity: 1,
    },
    {
        value: "link_scanner",
        name: "Link Scanner",
        short_description: "Crawl the entire site and verify all links and image URLs.",
        description: "This test will crawl the entire site, specified in the URL field, and verify that all of the links and image URLs are functional.",
        instructions: "Specify the main URL of the site you would like to check and let this test work it's magic.",
        icon: <LinkIcon className="test-type-icon" color="secondary" />,
        image: 'zenmode_test-graphics_link_scanner.png',
        complexity: 1,
    },
];

export default testTypes;