// src/components/ProjectForm.js
import React, { useState } from 'react';
import { TextField, Switch, FormControlLabel, FormGroup } from '@mui/material';

const ProjectForm = ({ formData, setFormData }) => {
    const [isEcommerce, setIsEcommerce] = useState(false);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));

        //console.log(formData)
    };

    return (
        <>
            <TextField
                fullWidth
                margin="normal"
                name="name"
                label="Name"
                placeholder="Enter a Project Name"
                value={formData.name || ''}
                onChange={handleChange}
            />
            <FormControlLabel
                control={<Switch checked={formData.wizard_active || false} name="wizard_active" onChange={handleChange} />}
                label="Create recommended tests automatically"
            />
            {formData.wizard_active && (
                <>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="homepage_url"
                        label="Homepage URL"
                        placeholder="Enter Homepage URL"
                        value={formData.homepage_url || ''}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        name="homepage_cta_text"
                        label="Homepage CTA Text"
                        placeholder="Enter Homepage CTA Text"
                        value={formData.homepage_cta_text || ''}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        name="contact_form_url"
                        label="Contact Form URL"
                        placeholder="Enter Contact Form URL"
                        value={formData.contact_form_url || ''}
                        onChange={handleChange}
                        required
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={formData.is_ecommerce || false} name="is_ecommerce" onChange={handleChange} />}
                            label="Is this an eCommerce site?"
                        />
                    </FormGroup>
                    {formData.is_ecommerce && (
                        <>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="single_product_page_url"
                                label="Single Product Page URL"
                                placeholder="Enter Single Product Page URL"
                                value={formData.single_product_page_url || ''}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="cart_url"
                                label="Cart URL"
                                placeholder="Enter Cart URL"
                                value={formData.cart_url || ''}
                                onChange={handleChange}
                                required
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ProjectForm;
