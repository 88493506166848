import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@awesome.me/kit-70027d07fa/icons/classic/solid';
import useUserStore from '../../store/useUserStore';
import useTestRunStore from '../../store/useTestRunStore';
import ResultCard from './ResultCard';
import ResultIndicator from './ResultIndicator';
import './ResultsTimeline.css';
import ResultsTimelineChart from './ResultsTimelineChart'; // Import the new component

import { useResults } from '../../hooks/useResults.js';

const ResultsTimeline = ({ testDetails }) => {
    const { appUserId } = useUserStore(state => ({ appUserId: state.appUserId }));
    const testRunCompleted = useTestRunStore((state) => state.testRunCompleted);
    const setTestRunCompleted = useTestRunStore((state) => state.setTestRunCompleted);

    const {
        data: results,
        isLoading: isResultsLoading,
        error: resultsError,
        refetch: resultsRefetch
    } = useResults(appUserId, { testId: testDetails._id }, {
        staleTime: 1 * 60 * 1000, // 5 minutes of data being considered fresh
        enabled: !!testDetails._id // Ensure the query only runs if testDetails._id is available
    });

    useEffect(() => {
        if (testRunCompleted) {
            resultsRefetch(); // Only refetch when a test run has completed
            setTestRunCompleted(false);
        }
    }, [testRunCompleted, resultsRefetch, setTestRunCompleted]);

    return (
        <div className="results-timeline">
            <ResultsTimelineChart results={results} testDetails={testDetails} />
            <div className="results-list">
                <Accordion className="unclickable">
                    <AccordionSummary>
                        <Typography variant="subtitle1" style={{ flexBasis: '220px', flexShrink: 0 }}>
                            {new Date(testDetails.next_run).toLocaleString()}
                        </Typography>
                        <ResultIndicator />
                    </AccordionSummary>
                </Accordion>
                {Array.isArray(results) && results.map((result) => (
                    <Accordion key={result._id} className="result-timeline-accordion">
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                            aria-controls={`panel-${result._id}-content`}
                            id={`panel-${result._id}-header`}
                        >
                            <Typography variant="subtitle1" style={{ flexBasis: '220px', flexShrink: 0 }}>
                                {new Date(result.datetime).toLocaleString()}
                            </Typography>
                            <ResultIndicator result={result.pass} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <ResultCard {...result} testId={testDetails._id} resultsRefetch={resultsRefetch} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
};

export default ResultsTimeline;
