import React, { useState } from "react";
import axios from 'axios';
import { Container, Box, Typography, Avatar, Grid, Button, Alert } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import EditUserForm from '../components/Account/EditUserForm';
import useUserStore from '../store/useUserStore';
import ManageSubscriptionButton from "../components/Account/ManageSubscriptionButton";

const ProfilePage = () => {
    const { user, logout } = useAuth0();
    const { appUser, updateAppUser } = useUserStore();
    const [editMode, setEditMode] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    if (!appUser) {
        return null;
    }

    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    const handleSuccess = (updatedUser) => {
        updateAppUser(updatedUser);
        setEditMode(false);
        setMessage('Profile updated successfully.');
    };

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_ZENMODE_APP_URL,
            },
        });
    };

    const handleResetPassword = async () => {
        try {
            const response = await axios.post(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
                client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
                email: user.email,
                connection: 'Username-Password-Authentication'
            }, {
                headers: { 'content-type': 'application/json' }
            });

            setMessage('Password reset email sent successfully.');
            console.log(response.data);
        } catch (error) {
            setError('Failed to send password reset email. Please try again.');
            console.error(error);
        }
    };

    return (
        <Container maxWidth="md" className="profile">
            {message && <Alert severity="success" sx={{ marginBottom: 2 }}>{message}</Alert>}
            {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}

            {!editMode ? (
                <Box my={4}>
                    <Typography variant="h4" className="page-title fancy-font" sx={{ marginBottom: "2em !important" }}>
                        Your Profile
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                    </Typography>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={2}>
                            <Avatar alt="Profile" src={user.picture} sx={{ width: 100, height: 100 }} />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            {(appUser.first_name && appUser.last_name) && (
                                <Typography variant="h6">{appUser.first_name} {appUser.last_name}</Typography>
                            )}
                            {appUser.nickname && (
                                <Typography variant="subtitle1">{appUser.nickname}</Typography>
                            )}
                            <Typography variant="subtitle1">{appUser.email}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" component="pre" sx={{ overflowX: "auto" }}>
                                {/*JSON.stringify(user, null, 2)*/}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box className="account-buttons">
                        <Button onClick={handleEditClick}>Edit Profile</Button>
                        <Button onClick={handleResetPassword}>Reset Password</Button>
                        <Button onClick={handleLogout}>Log Out</Button>
                        <ManageSubscriptionButton />
                    </Box>
                </Box>
            ) : (
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Edit Profile
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        The account email cannot be changed.
                    </Typography>
                    <EditUserForm
                        appUser={appUser}
                        onCancel={handleCancel}
                        onSuccess={handleSuccess}
                    />
                </Box>
            )}
        </Container>
    );
};

export default ProfilePage;
